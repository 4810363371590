<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

<section class="cover-page">
    <ngx-spinner size="medium" [type]="typeSelected">
        <p style="color: white">{{percentageMessage}}</p>
    </ngx-spinner>
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-md-7 left p-0">
                <div class="bg-cover d-flex flex-column justify-content-md-center p-2 p-md-4">
                    <div class="text-center mt-0 mt-md-auto">
                        <img class="img-fluid" src="assets/images/logo-1.png" alt="Logo-1">
                    </div>
                    <footer class="mt-auto">
                        <p class="text-white fs-12 m-0">Copyright
                            2023 Citrin cooperman. All Rights
                            Reserved.
                        </p>
                    </footer>
                </div>
            </div>
            <!-- <div class="col-md-5 right p-0">
                    <div class="h-100 d-flex justify-content-center align-items-center">
                        <div class="text-center">
                            <div class="mb-4">
                                <img src="assets/images/logo-2.png" alt="Logo">
                            </div>
                        </div>
                    </div>
                </div> -->
        </div>
    </div>
</section>

<!-- warning Modal -->
<div class="modal" tabindex="-1" id="cacheclear" [ngStyle]="{'display':displayClearCacheWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="cacheclear">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Info</h5>
            </div>
            <div class="modal-body text-center">
               <p></p>
                <p class="fs-16">{{warningMessage}}</p>
                <button type="button" (click)="cacheClearLogout()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>