import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { LoginComponent } from './components/login/login.component';
import { EngagementletterdashboardComponent } from './components/engagementletterdashboard/engagementletterdashboard.component';
import { BlocksComponent } from './components/blocks/blocks.component';
import { FieldsComponent } from './components/fields/fields.component';
import { AuthGuard } from './core/auth.guard';
import { TemplatesComponent } from './components/templates/templates.component';
import { TemplatecreateComponent } from './components/templates/templatecreate/templatecreate.component';
import { AccessrightssettingComponent } from './components/accessrightssetting/accessrightssetting.component';
import { EdittemplateComponent } from './components/templates/edittemplate/edittemplate.component';
import { BulkletterdashboardComponent } from './components/bulkletterdashboard/bulkletterdashboard.component';
import { CreatebulkletterComponent } from './components/bulkletterdashboard/createbulkletter/createbulkletter.component';
import { BulkletterbatcheditComponent } from './components/bulkletterdashboard/bulkletterbatchedit/bulkletterbatchedit.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { CreateclientbulkletterComponent } from './components/bulkletterdashboard/createclientbulkletter/createclientbulkletter.component';
import { EditclientbulkletterComponent } from './components/bulkletterdashboard/editclientbulkletter/editclientbulkletter.component';
import { RemindersettingComponent } from './components/remindersetting/remindersetting.component';

export const routes: Routes = [
  // { path: '', redirectTo: "/login", pathMatch: 'full' }, 
  { path: 'id_token', redirectTo: '' },
  { path: 'access_token', redirectTo: '' },
  { path: '', component: LoginComponent },
  { path: 'editbulkletter/:Id', component: BulkletterbatcheditComponent, canActivate: [AuthGuard] },
  { path: 'editcreateclientbulkletter/:Id', component: EditclientbulkletterComponent, canActivate: [AuthGuard] },
  { path: 'fields', component: FieldsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'blocks', component: BlocksComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'engagementletterdashboard', component: EngagementletterdashboardComponent, canActivate: [AuthGuard] },
  { path: 'bulkletterdashboard', component: BulkletterdashboardComponent, canActivate: [AuthGuard] },
  { path: 'createbulkletter', component: CreatebulkletterComponent, canActivate: [AuthGuard] },
  { path: 'createclientbulkletter', component: CreateclientbulkletterComponent, canActivate: [AuthGuard] },
  { path: 'templates', component: TemplatesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'settings', component: RemindersettingComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'templatecreate', component: TemplatecreateComponent, canActivate: [AuthGuard] },
  { path: 'edittemplate/:Id', component: EdittemplateComponent, canActivate: [AuthGuard] },
  { path: 'accessrights', component: AccessrightssettingComponent, canActivate: [AuthGuard] },
  { path: 'questions', component: QuestionsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  //{path:'id_token',component:DashboardComponent}

];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule {
  userId() {
    const jsonData = localStorage.getItem("ScreenAcessPermission");
    console.log(jsonData);
    // return jsonData == null ? 0 : +JSON.parse(jsonData).userId;
  }
}
