<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-2" #mainContent>
            <section class="container-fluid">
                <ngx-spinner size="medium" [type]="typeSelected">
                    <p style="color: white">{{percentageMessage}}</p>
                </ngx-spinner>
                <div>
                    <!-- Accordian 1 starts  -->
                    <form id="BulkClientLetterForm" [formGroup]="BulkClientLetterForm">
                        <div class="bg-white px-4 py-3 rounded mb-2 mb-md-4">
                            <div class="accordion accordion-flush" id="basic-details">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button id="collapseAccordianOneButton" #collapseAccordianOneButton
                                            class="accordion-button collapsed p-0 fs-6 fw-semibold highlight shadow-none bg-white collapsed"
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseAccordianOneBasicDetail"
                                            (click)="collapseOneFilter()" aria-expanded="false"
                                            aria-controls="flush-collapseAccordianOneBasicDetail">
                                            Basic Details
                                        </button>
                                    </h2>
                                    <div id="collapseAccordianOne" #collapseAccordianOne
                                        class="accordion-collapse collapse show" data-bs-parent="#basic-details">
                                        <div class="accordion-body p-2">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-1">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Batch ID
                                                        </label>
                                                        <input type="text" readonly formControlName="BatchID"
                                                            [(ngModel)]="batchIDFromApi" class="form-control"
                                                            id="exampleFormControlInput1" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Admin
                                                        </label>
                                                        <input type="text" readonly formControlName="Admin"
                                                            [(ngModel)]="this.Username" class="form-control"
                                                            id="exampleFormControlInput1" placeholder="Admin">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="mb-2 mb-md-3 mb-lg-4 px-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Search & Load Client(s) based on
                                                        </label>
                                                        <div class="mt-2">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio"
                                                                    name="searchOption" id="client-name-radio"
                                                                    value="client-name" [checked]="clientIdRadioButton"
                                                                    [(ngModel)]="searchOption"
                                                                    (change)="onSearchOptionChange($event)"
                                                                    #clientNameRadio>
                                                                <label class="form-check-label"
                                                                    for="client-name-radio">Client
                                                                    Name/ID</label>
                                                            </div>
                                                            <div class="form-check form-check-inline ms-lg-3">
                                                                <input class="form-check-input" type="radio"
                                                                    name="searchOption" [checked]="emailRadioButton"
                                                                    id="email-radio" value="email"
                                                                    [(ngModel)]="searchOption"
                                                                    (change)="onSearchOptionChange($event)" #emailRadio>
                                                                <label class="form-check-label" for="email-radio">Email
                                                                    Address</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-md-6 col-lg-3" [hidden]="!clientIdRadioButton">
                                                    <div class="mb-2 mb-md-3 mb-lg-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Client ID/Name
                                                            <span class="err-color">*</span></label>
                                                        <!-- <ng-multiselect-dropdown name="ClientIdName"
                                                            formControlName="ClientIdName"
                                                            (ngModelChange)="onChangeClientIdName()"
                                                            [placeholder]="'Select Client'"
                                                            [settings]="dropdownSettingsClient"
                                                            [(ngModel)]="selectedClientItems" [data]="ClientIdNameList">
                                                        </ng-multiselect-dropdown> -->
                                                        <p-multiSelect #multiSelectRef class="table-multiselect"
                                                            formControlName="ClientIdName" [options]="ClientIdNameList"
                                                            [showToggleAll]="true" [(ngModel)]="selectedClientItems"
                                                            optionLabel="ClientName" optionValue="ClientId"
                                                            [virtualScroll]="true" [filter]="true" [itemSize]="34"
                                                            placeholder="Select Clients" filterPlaceholder="Search"
                                                            (ngModelChange)="onChangeClientIdName()">
                                                            <ng-template pTemplate="header">
                                                                <div class="header-container p-multiselect-custom-icon">
                                                                    <i class="search-clear-icon" pTooltip="Clear"
                                                                        (click)="resetFilter(multiSelectRef)"></i>
                                                                </div>
                                                            </ng-template>

                                                            <ng-template pTemplate="item" let-clientdata>
                                                                <div class="country-item">
                                                                    <div>{{clientdata.value.ClientName}}
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </p-multiSelect>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-3" [hidden]="!emailRadioButton">
                                                    <div class="mb-2 mb-md-3 mb-lg-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Email Address
                                                            <span class="err-color">*</span></label>
                                                        <!-- <ng-multiselect-dropdown name="ClientEmail"
                                                            formControlName="ClientEmail"
                                                            (ngModelChange)="onChangeEmail()"
                                                            [placeholder]="'Select Email'"
                                                            [settings]="dropdownSettingsEmail"
                                                            [(ngModel)]="selectedEmailItems"
                                                            [data]="ClientIdWithMailList">
                                                        </ng-multiselect-dropdown> -->
                                                        <ng-select formControlName="ClientEmail" [virtualScroll]="true"
                                                            [(ngModel)]="selectedEmailItems"
                                                            [items]="ClientIdWithMailList" bindLabel="Email"
                                                            bindValue="Email" [searchable]="true"
                                                            placeholder="Select Email" (change)="onChangeEmail()">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="mb-2 mb-md-3 mb-lg-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Tax Year
                                                            <span class="err-color">*</span></label>
                                                        <ng-multiselect-dropdown name="TaxYears" class="myTaxYears"
                                                            formControlName="TaxYears" (ngModelChange)='onChangeYears()'
                                                            [placeholder]="'Select Tax Years'"
                                                            [(ngModel)]="defaultselectedYear"
                                                            [settings]="dropdownSettingsTaxyear" [data]="taxYears"
                                                            [disabled]="selectyearsdropdown">
                                                        </ng-multiselect-dropdown>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div
                                                    class="col-12 col-lg-12 d-flex justify-content-end gap-4 flex-wrap">
                                                    <button type="button" style="margin-top: 20px;"
                                                        (click)="editFetchClientdetailsUsingClientIDEmail()"
                                                        class="btn p-btn"
                                                        [disabled]="!(isreloadavail && BulkClientLetterForm.valid) || isletterprocessdisable">
                                                        Load Client Details
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                    <!-- Accordian 1 End  -->
                    <!--  Accordian 2 Start  -->
                    <div class="bg-white px-4 py-3 rounded mb-2 mb-md-4" *ngIf="this.BatchClientletterlist == true">
                        <div class="accordion accordion-flush" id="basic-details">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button id="collapseAccordianTwoButton" #collapseAccordianTwoButton
                                        class="accordion-button collapsed p-0 fs-6 fw-semibold highlight shadow-none bg-white collapsed"
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseAccordianTwoBasicDetail"
                                        (click)="collapseTwoFilter()" aria-expanded="false"
                                        aria-controls="flush-collapseAccordianTwoBasicDetail">
                                        Client Details
                                    </button>
                                </h2>
                                <div id="collapseAccordianTwo" #collapseAccordianTwo
                                    class="accordion-collapse collapse show" data-bs-parent="#basic-details">
                                    <div class="accordion-body p-2">
                                        <div class="bg-white">
                                            <div class="d-flex justify-content-between flex-wrap mb-2">
                                            </div>
                                            <!-- Table -->
                                            <div class="table-responsive position-relative">
                                                <p-table #dt [value]="clientDataUsingIdEmail"
                                                    styleClass="p-datatable-gridlines" dataKey="id" [rows]="10"
                                                    [resizableColumns]="true" sortMode="multiple" [scrollable]="true"
                                                    scrollHeight="1000px" [showCurrentPageReport]="true"
                                                    [paginator]="true" [(first)]="firstpage"
                                                    currentPageReportTemplate="{first} to {last} of {totalRecords}">
                                                    <ng-template pTemplate="colgroup">
                                                        <colgroup>
                                                            <col style="width:20%">
                                                            <col style="width:38%">
                                                            <col style="width:25%">
                                                            <col style="width:40%">
                                                            <col style="width:30%">
                                                            <col style="width: 23%">
                                                            <col style="width:15%">


                                                        </colgroup>
                                                    </ng-template>


                                                    <ng-template pTemplate="caption">

                                                        <h5 class="highlight mb-0 fs-6 fw-semibold"> </h5>
                                                        <br />
                                                        <div
                                                            class="table-header d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <!-- <span class="p-input-icon-left">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" [(ngModel)]="searchfilter"
                                                    placeholder="Search by column values" class="shadow-none" />
                                            </span> -->
                                                            </div>
                                                        </div>
                                                    </ng-template>

                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <!-- <th class="text-center"><input type="checkbox" style="width: 13px;"
                                                #selectAllCheckbox (change)="toggleAllCheckboxes($event)"
                                                [checked]="isAllCheckBoxChecked()"
                                                [disabled]="selectAllCheckBoxDisabled" /></th> -->
                                                            <th pResizableColumn pSortableColumn="ClientId">Client ID
                                                                <p-sortIcon field="ClientId">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th pResizableColumn pSortableColumn="ClientName">Client
                                                                Name<p-sortIcon field="ClientName">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th pResizableColumn pSortableColumn="PartnerName"> Partner
                                                                (PIC)<p-sortIcon field="PartnerName">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th pResizableColumn pSortableColumn="Template">Template
                                                                Name
                                                                <p-sortIcon field="Template">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th pResizableColumn pSortableColumn="ReturnTypeCode">
                                                                Return Type Code<p-sortIcon field="ReturnTypeCode">
                                                                </p-sortIcon>
                                                            </th>
                                                            <th pResizableColumn>Choose Paragraph
                                                            </th>
                                                            <th pResizableColumn>Action</th>
                                                        </tr>

                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-clientdata
                                                        let-rowIndex="rowIndex">
                                                        <tr>
                                                            <td>{{clientdata.ClientId}}</td>
                                                            <td>{{clientdata.ClientName}}</td>
                                                            <td>{{clientdata.PartnerName}}</td>
                                                            <td>
                                                                <ng-select #ngSelectTemplate appendTo="body"
                                                                    [(ngModel)]="templateSelections[clientdata.BulkLettersId]"
                                                                    [items]="templatedynamicnames"
                                                                    (change)="onTemplateChange(templateSelections[clientdata.BulkLettersId], clientdata.BulkLettersId)"
                                                                    bindLabel="name" bindValue="id" [searchable]="true"
                                                                    [clearable]="false" placeholder="Select Template">
                                                                </ng-select>
                                                            </td>
                                                            <td>
                                                                <!-- <p-multiSelect class="table-multiselect"
                                                                    [(ngModel)]="returnTypeSelections[clientdata.BulkLettersId]"
                                                                    [options]="returnTypeOptions[clientdata.BulkLettersId]"
                                                                    [disabled]="returnTypeButtonDisable[clientdata.BulkLettersId]"
                                                                    appendTo="body" placeholder="Select Code"
                                                                    optionLabel="ReturnType" optionValue="ReturnType"
                                                                    [filter]="true" filterPlaceholder="Search"
                                                                    defaultLabel="Select All" [showHeader]="true">
                                                                </p-multiSelect> -->
                                                                <ng-select #ngSelectReturntype appendTo="body"
                                                                    [(ngModel)]="returnTypeSelections[clientdata.BulkLettersId]"
                                                                    [items]="returnTypeOptions[clientdata.BulkLettersId]"
                                                                    [disabled]="returnTypeButtonDisable[clientdata.BulkLettersId]"
                                                                    bindLabel="ReturnType" bindValue="ReturnType"
                                                                    [searchable]="true" [clearable]="false"
                                                                    placeholder="Select Code">
                                                                </ng-select>
                                                            </td>
                                                            <td class="text-center"><span> <i
                                                                        class="edit-feild-icon dark"
                                                                        [class.disabled]="!isIconEnabled(clientdata.BulkLettersId)"
                                                                        (click)="ChooseParagraphModalForClientDetails($event, clientdata.ClientId, clientdata.ClientName, clientdata.BulkLettersId)"></i></span>
                                                            </td>
                                                            <td class="text-center">
                                                                <span> <i class="delete-icon dark" pTooltip="Delete"
                                                                        tooltipPosition="top"
                                                                        (click)="deleteModal(clientdata.BulkLettersId, clientdata.ClientName)"></i></span>
                                                            </td>
                                                        </tr>



                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="12" style="text-align: center;">No engagement
                                                                letters found</td>
                                                        </tr>
                                                    </ng-template>

                                                </p-table>

                                            </div>
                                            <div class="d-flex justify-content-end flex-wrap gap-3 mt-2 mt-lg-3">
                                                <!--<button type="button" (click)="Discardgobackdelete()" #discardtbtn
                                class="btn p-btn-outline">Discard & Go Back </button>-->
                                                <button type="button" (click)="addClientModal()" class="btn p-btn ">Add
                                                    Client
                                                </button>
                                                <button type="button" (click)="saveDraft()"
                                                    [hidden]="clientDetailsSaveDraftBtnHidden"
                                                    class="btn p-btn-outline">Save
                                                    as
                                                    Draft</button>
                                                <button type="button" [disabled]=this.ClientDetailsNextButton
                                                    (click)="ClientDetailsSave()" class="btn p-btn">Next</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--  Accordian 2 end  -->
                    <!--  Accordian 3 Start  -->

                    <!-- Table -->
                    <div *ngIf="this.BatchBulkletterlist == true"
                        class="bg-white px-4 py-3 rounded mb-2 mb-md-4 bulk-letter-table">
                        <div class="table-responsive position-relative">
                            <p-table #dt1 [value]="bulkLetterData" styleClass="p-datatable-gridlines" dataKey="id"
                                [rows]="10" [resizableColumns]="true" sortMode="multiple" [scrollable]="true"
                                scrollHeight="1000px" [showCurrentPageReport]="true" [paginator]="true"
                                [(first)]="firstpagebulk"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                [globalFilterFields]="['ClientId','ClientName','PartnerName','Office','SignatoryEmailId','SignatoryFirstName', 'ReturnTypeCode']"
                                (onFilter)="updateAndFilter($event)">
                                <ng-template pTemplate="colgroup">
                                    <colgroup>
                                        <!-- <col style="width:38%"> -->
                                        <col style="width:10%">
                                        <col style="width:27%">
                                        <col style="width:40%">
                                        <col style="width:33%">
                                        <col style="width:27%">
                                        <col style="width:40%">
                                        <col style="width:25%">
                                        <col style="width:46%">
                                        <col style="width:40%">
                                        <col style="width:15%">
                                    </colgroup>
                                </ng-template>

                                <ng-template pTemplate="caption">
                                    <div class="table-header d-flex justify-content-between align-items-center">
                                        <h5 class="highlight mb-0 fs-6 fw-semibold">Bulk Letter List </h5>
                                        <div>

                                            <button type="button" class="btn p-btn  me-3" #CreateFormEdit
                                                data-toggle="modal" data-target="#CreateFormEdit"
                                                [disabled]="!(specialUser && !isEdit7216Enable)"
                                                (click)="createEdit7216()">Edit 7216 Provider
                                            </button>

                                            <button type="button" (click)="bulkClientDeleteModal()"
                                                class="btn p-btn  me-3" [disabled]="isButtonEnabled">Remove
                                                Clients</button>

                                            <button type="button" class="btn p-btn me-3" data-bs-toggle="modal"
                                                data-bs-target="#add-attachment" (click)="bulkAddAttachmentsmodal()">
                                                Add Bulk Attachments to Email</button>
                                            <span class="p-input-icon-left" id="searchfilter">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" [(ngModel)]="searchfilter"
                                                    (input)="updateAndFilter($event.target.value)"
                                                    placeholder="Search by column values" class="shadow-none" />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="text-center"><input type="checkbox" style="width: 13px;"
                                                #selectAllCheckbox (change)="toggleAllCheckboxes($event)"
                                                [checked]="isAllCheckBoxChecked()"
                                                [disabled]="selectAllCheckBoxDisabled" /></th>
                                        <th pResizableColumn pSortableColumn="ClientId">Client ID<p-sortIcon
                                                field="ClientId">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="ClientName">Client Name<p-sortIcon
                                                field="ClientName">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="PartnerName"> Partner (PIC)<p-sortIcon field="PartnerName">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="Office">Office<p-sortIcon field="Office">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="ReturnTypeCode">ReturnTypeCode<p-sortIcon
                                                field="ReturnTypeCode">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="IndividualChoosePara">Fee Type<p-sortIcon
                                                field="IndividualChoosePara">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="SignatoryFirstName">Signatory First
                                            Name<p-sortIcon field="SignatoryFirstName">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="SignatoryEmailId">Signatory Email
                                            ID<p-sortIcon field="SignatoryEmailId">
                                            </p-sortIcon>
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-client>

                                    <tr>
                                        <td class="text-center"> <input type="checkbox" #selectAllCheckbox
                                                [value]="client.BulkLettersId" [(ngModel)]="client.checked"
                                                (change)="toggleSingleCheckbox(client.BulkLettersId, client.checked)">
                                        </td>
                                        <td>{{client.ClientId}}</td>
                                        <td>{{client.ClientName}}</td>
                                        <td>{{client.PartnerName}}</td>
                                        <td>{{client.Office}}</td>
                                        <td>{{client.ReturnTypeCode}}</td>
                                        <td>{{client.IndividualChoosePara}}</td>
                                        <td>{{client.SignatoryFirstName }}</td>
                                        <td>
                                            <div style="word-wrap: break-word; white-space: normal;">
                                                {{ client.SignatoryEmailId }}
                                            </div>
                                        </td>

                                        <td class="text-center align-middle">
                                            <div class="custom-icon"
                                                (click)="showMenu($event, menu, client.BulkLettersId,client.ClientId,client.ClientName,client.PartnerId,client.SignatoryEmailId,client.Is7216Available,client.TemplateId,client.TemplateName)">
                                                <i class="pi pi-ellipsis-v"></i>
                                            </div>
                                            <p-menu #menu [popup]="true" [class]="menuClicked ? 'custom-menu' : ''"
                                                [model]="items">
                                            </p-menu>
                                        </td>
                                    </tr>



                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="12" style="text-align: center;">No engagement letters found</td>
                                    </tr>
                                </ng-template>

                            </p-table>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end flex-wrap gap-3 mt-2 mt-lg-3">
                        <button type="button" (click)="saveDraft()" [hidden]="batchDetailsSaveDraftBtnHidden"
                            class="btn p-btn-outline">Save as
                            Draft</button>
                        <button type="button" (click)="createBulkLetterConfirmModal()"
                            *ngIf="BatchBulkletterlist == true" class="btn p-btn">Create Bulk
                            letter</button>
                    </div>





                    <!--  Accordian 3 end  -->

                </div>
            </section>
        </main>
    </div>
</section>
<div [innerHTML]="trustedHtml"></div>
<!-- All Popup Modal -->

<!-- Creation template -->
<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="signingblock" #siginingcontent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Client details choose paragraph modal -->
<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayClientDetailsChooseParaModal}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content" id="viewChooseTemplate">
            <!-- Modal Header -->
            <div class="modal-header border-0 px-3 py-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="d-flex align-items-center h-100">
                                <h6 class="text-dark fw-bold m-0">Choose Paragraphs</h6>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 px-2">
                            <div class='text-end'>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" alt="close"
                                    (click)="closeClientDetailsChooseParaModal()"></button>
                            </div>
                            <div class="d-flex justify-content-between align align-items-center flex-wrap">
                                <div *ngIf="!notToShowClientIdClientName">
                                    <p class="fw-bold mb-1">Client ID: <span class="fw-medium px-2">
                                            {{this.clientId}}</span>
                                    </p>
                                    <p class="fw-bold mb-1">Client Name: <span class="fw-medium px-2">
                                            {{this.ClientName}}</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="border h-100 para">
                                <div class="template_preview_panel">
                                    <div class="preview_header">
                                        <h5 class="text-dark fw-bold fs-6">Choose Paragraphs</h5>
                                    </div>
                                    <div class="preview_body h-100 p-3 preview_body_scroll"
                                        #clientchooseParagraphScrollPosition>
                                        <div id="previewquestionblock" #previewquestionblock class=" new_height_align">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="border h-100">
                                <div class="template_preview_panel"
                                    [ngClass]="togglefullscreen ? 'panel-fullscreen' : ''">
                                    <div class="preview_header">
                                        <h5 class="text-dark fw-bold fs-6">Letter Preview</h5>
                                    </div>
                                    <div class="preview_body h-100 preview_body_scroll"
                                        #clientChooseParagraphLetterPreviewScrollPosition>
                                        <div id="previewareablock" #previewareablock
                                            class="preview_inner new_height_align  p-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">

                <div>
                    <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                        <button type="button" (click)="closeClientDetailsChooseParaModal()"
                            class="btn p-btn-outline">Close</button>
                        <button type="button" id="submitQuestionBlocks" (click)="QuestionsBlocksubmitForClientDetails()"
                            class="btn p-btn">Submit</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="errorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModalexcel()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p *ngIf="this.excelerrorchecking >0" class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}<br>{{errorclientid}}</p>
                <p *ngIf="this.excelerrorchecking <=0 " class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}</p>
                <button type="button" id="errorOk" (click)="closeErrorModalexcel()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="successModal">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button id="succesOk" type="button" (click)="closeSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- savedraft Modal -->
<div class="modal" tabindex="-1" id="saveDraftModal" [ngStyle]="{'display':displaysaveDraft}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="saveDraftModal">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Save & Redirect</h5>
                <button type="button" class="btn-close shadow-none" (click)="saveDraftClose()"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to save the details and go back?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" (click)="saveDraftNo()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="saveDraftYes()">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Common Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayCommonErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayCommonErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeDeleteErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" [innerHTML]="errorMessage"></p>
                <button id="commonerrorOk" type="button" (click)="closeDeleteErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal Save draft -->
<div class="modal" tabindex="-1" id="savedraftsuccess" [ngStyle]="{'display':displaySuccesssavedraftModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="saveDraftSuccessModal">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccesssavedraftModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessagesavedraft}}</p>
                <button type="button" id="saveDraftOk" (click)="okSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Idle time confirmation popup -->
<div class="modal" tabindex="-1" id="backLogModal" [ngStyle]="{'display':displayIdleConfirm}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayIdleConfirm">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Redirect</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeIdlePopup()"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">You have been inactive after creating a batch of letters. Please press
                    "Continue in this screen" or "Close" to continue to use this batch, otherwise this batch will be
                    closed and redirected to dashboard in 10minutes.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal"
                    (click)="closeIdlePopup()">Continue in this screen</button>
                <button type="button" class="btn p-btn ms-2" (click)="SaveAndRedirect('click')">Save & redirect</button>
            </div>
        </div>
    </div>
</div>
<!-- Missing Field CLientId warning Modal -->
<div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayWarningModal">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">{{ClientIdErrors}}</p>
                <button type="button" id="warningOk" (click)="closeWarningModal()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" tabindex="-1" id="attachmenterror" [ngStyle]="{'display':displayErrorModalattachment}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="attachmenterrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16"> {{errorMessage}}</p>
                <button type="button" id="attachmenterrorOk" (click)="closeErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->
<div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayDeleteModal">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{deletePopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button id="deleteModalNo" type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button id="deleteModalYes" type="button" (click)="deleteBulkLetterById()"
                    class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>

<!--7216 Modal -->
<div class="modal" id="CreateFormEdit" tabindex="-1" aria-modal="true" role="dialog"
    [ngStyle]="{'display':FormEditCkeditor}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered  modal-lg" id="CreateEdit7216ModalDialog">
        <div class="modal-content p-4" id="Edit7216">
            <div class="modal-header border-0 p-0">
                <h5 class="block_title ">Edit 7216 Provider<span class="mr-2"></span>
                </h5>
                <button type="button" class="btn-close shadow-none" (click)="closeeditor()" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body p-0">
                <div class="row no-gutters">
                    <div class="col-12 col-sm-12 col-md-12 mt-3" #editornew>
                        <div class=" edit_content_preview">
                            <ckeditor [(data)]="this.formeditjsonvalue.Content" tagName="textarea"
                                bodyClass='document-editor' [config]="config" (ready)="onReady( $event)"
                                (change)="onChangeevent( $event )" (focus)="onFocus( $event )" (key)="onkey($event)"
                                (blur)="onBlur( $event )" (paste)="onPaste( $event )" (keyup)="onkeyDown($event)"
                                (afterPaste)="onAfterPaste( $event )" (dragStart)="onDragStart( $event )"
                                (dragEnd)="onDragEnd( $event )" (drop)="onDrop( $event )">
                            </ckeditor>
                            <div class="modal-footer border-0 p-0 d-flex flex-column align-items-start">
                                <div class="d-flex w-100 mt-2">
                                    <h6 class="block_title Editing7216" style="
                                    margin-bottom: 0px;">Note:&nbsp;</h6>
                                    <span>This change will be applied to all the letters which has 7216 in this
                                        batch</span>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <form [formGroup]="updateFormEdit">
                                        <div class="row">
                                            <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                                                <button type="button" (click)="closeeditor()"
                                                    class="btn p-btn-outline">Cancel</button>
                                                <button type="button" (click)="updateFormEdit7216()"
                                                    class="btn p-btn ms-3">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Edit para Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayEditParaErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayEditParaErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeEditParaErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <!-- <p style="word-wrap:break-word;text-align:center;">Client ID : {{EditParaClientIdErrors}}</p> -->
                <button type="button" id="Okcommonerror" (click)="closeEditParaErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- PreviewSurvey Modal -->
<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="content" #surveyContent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Preview Modal -->
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreview}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closePreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <div *ngIf="this.pdfSrc" style="height: 100%;">
                                        <pdf-viewer class="customtaxlayerremove" [src]="pdfSrc" [stick-to-page]="true"
                                            [show-borders]="true" [render-text]="true"
                                            style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                        </pdf-viewer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- update Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displayupdateSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="successUpdate">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="updateSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">
                    {{updatesuccessMessage}}
                </p>
                <button type="button" id="UpdateSuccessOk" (click)="updateSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>

            </div>
        </div>
    </div>
</div>

<!-- Client Bulk Delete Modal -->
<div class="modal" tabindex="-1" id="bulkClientDeleteModal" [ngStyle]="{'display':displayBulkClientDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayBulkClientDeleteModal">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                <button type="button" (click)="onCloseBulkClientDeleteModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{bulkClientdeletePopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button id="deleteBulkletterByIdNo" type="button" (click)="onCloseBulkClientDeleteModal()"
                    class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                <button id="deleteBulkletterByIdYes" type="button" (click)="deleteBulkClientLetterById()"
                    class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Create Bulk Success Modal -->
<div class="modal" tabindex="-1" id="createsuccess" [ngStyle]="{'display':displaycreateSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="createSuccessModal">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="okSuccessModalcreate()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <p class="fs-16">{{LettersCount}}</p>
                <button type="button" id="createsuccessOk" (click)="okSuccessModalcreate()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- create bulk letter confirm modal -->
<div class="modal" tabindex="-1" id="createBulkletterConfirmPopup"
    [ngStyle]="{'display':displayCreateBulkletterConfirmPopup}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayCreateBulkletterConfirmPopup">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm</h5>
                <button type="button" (click)="onCloseCreateBulkletterConfirmModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{CreateBulkletterConfirmPopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button id="createBulkletterNo" type="button" (click)="onCloseCreateBulkletterConfirmModal()"
                    class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                <button id="createBulkletterYes" type="button" (click)="createbulkletter()"
                    class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>


<!-- Edit field Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayEditField}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content p-4" id="bulk-letter-edit-field">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Edit Fields </h5>
                <button type="button" class="btn-close shadow-none" (click)="editFieldscloseModal()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <p><span class="err-color">Note: </span>All the fields are mandatory</p>
            <div class="modal-body p-0" #editfieldScrollPosition>
                <form id="staticfieldsform" [formGroup]="StaticFieldsForm">
                    <div class="p-3 border rounded" id="ClientIfovalues">
                        <div class="row mb-2">
                            <span class="d-block mb-2 fw-bold">1.Client Details:</span>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Client ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="ClientID"
                                        placeholder="Client ID" name="ClientID" readonly
                                        [(ngModel)]="this.PartnerValues.ClientID"
                                        [formControl]="StaticFieldsForm.controls['ClientID']">
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Signatory Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SignatoryEmailId"
                                        placeholder="Signatory Email ID" name="SignatoryEmailId"
                                        [(ngModel)]=" this.PartnerValues.SignatoryEmailId"
                                        [formControl]="StaticFieldsForm.controls['SignatoryEmailId']">
                                    <ng-container *ngFor="let validation of validation_messages.SignatoryEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsForm.get('SignatoryEmailId').hasError(validation.type) && (StaticFieldsForm.get('SignatoryEmailId').dirty || StaticFieldsForm.get('SignatoryEmailId').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3" [hidden]="isspouseemailid">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Spouse Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SpouseEmailId"
                                        placeholder="Spouse Email ID" name="SpouseEmailId"
                                        [(ngModel)]="this.PartnerValues.SpouseEmailId "
                                        [formControl]="StaticFieldsForm.controls['SpouseEmailId']">

                                    <ng-container *ngFor="let validation of validation_messages.SpouseEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsForm.get('SpouseEmailId').hasError(validation.type) && (StaticFieldsForm.get('SpouseEmailId').dirty || StaticFieldsForm.get('SpouseEmailId').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1 me-2">Tax Year <span
                                            class="err-color">*</span></label>
                                    <ng-multiselect-dropdown name="TaxYears" id="TaxYears" class="myTaxYears"
                                        [placeholder]="'Select Tax Years'" [(ngModel)]="selectedtaxyeareditfield"
                                        [formControl]="StaticFieldsForm.controls['selectedYearsformedit']"
                                        [settings]="dropdownSettings_taxyear" [data]="taxyears_bind">
                                    </ng-multiselect-dropdown>
                                    <ng-container *ngFor="let validation of validation_messages.TaxYears">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsForm.get('TaxYears').hasError(validation.type) && (StaticFieldsForm.get('TaxYears').dirty || StaticFieldsForm.get('TaxYears').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="mddformbody" #mddformbody class="row m-0 p-0">

                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <!-- Add title -->
                            <span class="d-block mb-2 fw-bold">2.Child Entities:</span>
                            <!-- <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-md-6 col-lg-6">
                                        <div class="mb-2 mb-md-3 mb-lg-3">
                                            <label for="exampleFormControlInput1" class="form-label mb-1">Child Entities
                                            </label>
                                            <input type="text" class="form-control" id="ChildEntities"
                                                name="ChildEntities"
                                                [formControl]="StaticFieldsForm.controls['ChildEntities']"
                                                placeholder="Child Entities">
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4 d-flex align-items-center">
                                        <button type="button" #addeditclientbtn class="btn p-btn mb-2 mb-md-0"
                                            (click)=" onAddchildEntityOptions()">Add</button>
                                    </div>
                                    <div class="col-lg-8">
                                        <div class="mb-2 mb-md-3 mb-lg-4">
                                            <div class="response_tag_wrapper" *ngIf="addEntityOptions!= ''">
                                                <p *ngFor="let addRes of addEntityOptions; let i=index;"
                                                    class="response_tag">{{addRes}}<a><i class="fa fa-times"
                                                            (click)=" onRemoveEntityOptions(addRes)"
                                                            aria-hidden="true"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6 col-lg-3">
                                        <label for="Childclientid" class="form-label mb-1">Child Client
                                            ID</label>
                                        <input type="text" autocomplete="off" class="form-control" id="Childclientid"
                                            name="Childclientid"
                                            [formControl]="StaticFieldsForm.controls['Childclientid']"
                                            placeholder="Child Client ID">
                                        <ng-container *ngFor="let validation of validation_messages.Childclientid">
                                            <div class="errorvl"
                                                *ngIf="StaticFieldsForm.get('Childclientid').hasError(validation.type) && (StaticFieldsForm.get('Childclientid').dirty || StaticFieldsForm.get('Childclientid').touched)">
                                                <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                    }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <label for="ChildEntities" class="form-label mb-1">Child Client
                                            Name</label>
                                        <input type="text" autocomplete="off" class="form-control" id="ChildEntities"
                                            name="ChildEntities"
                                            [formControl]="StaticFieldsForm.controls['ChildEntities']"
                                            placeholder="Child Client Name">
                                        <ng-container *ngFor="let validation of validation_messages.ChildEntities">
                                            <div class="errorvl"
                                                *ngIf="StaticFieldsForm.get('ChildEntities').hasError(validation.type) && (StaticFieldsForm.get('ChildEntities').dirty || StaticFieldsForm.get('ChildEntities').touched)">
                                                <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                    }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6 col-lg-3" *ngIf="isEditFixedFee">
                                        <label for="ChildFees" class="form-label mb-1">Child Fees</label>
                                        <input type="number" autocomplete="off" class="form-control" id="ChildFees"
                                            name="ChildFees" [formControl]="StaticFieldsForm.controls['ChildFees']"
                                            formControlName="ChildFees" placeholder="Child Fees">
                                        <ng-container *ngFor="let validation of validation_messages.ChildFees">
                                            <div class="errorvl"
                                                *ngIf="StaticFieldsForm.get('ChildFees').hasError(validation.type) && 
                                               (StaticFieldsForm.get('ChildFees').dirty || StaticFieldsForm.get('ChildFees').touched)">
                                                <label class="errorcolor">{{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <button type="button" #addeditclientbtn class="btn p-btn mb-2 mb-md-0"
                                            (click)="onAddchildEntityOptions()" [disabled]="!isAddButtonDisabled()"
                                            [hidden]="isAddBtnVisible" style="margin-top:19px">Add</button>
                                        <button type="button" #updateclientbtn class="btn p-btn mb-2 mb-md-0"
                                            (click)="onUpdatechildEntityOptions()" [disabled]="!isAddButtonDisabled()"
                                            [hidden]="isUpdateBtnVisible" style="margin-top:19px">Update</button>
                                    </div>
                                </div>

                                <div class="col-lg-12" style="margin-top: 20px;">
                                    <div class="col-md-12 col-lg-7">
                                        <div class="" *ngIf="childEntitiesControls.length > 0">
                                            <p-table #dt1 styleClass="p-datatable-gridlines" dataKey="childID"
                                                [value]="childEntitiesArrayValue" [rows]="3" [resizableColumns]="true"
                                                sortMode="multiple" [scrollable]="true" scrollHeight="1200px"
                                                [showCurrentPageReport]="true" [paginator]="true"
                                                [(first)]="childfirstpage"
                                                currentPageReportTemplate="{first} to {last} of {totalRecords}">
                                                <ng-template pTemplate="colgroup">
                                                    <colgroup>
                                                        <col style="width:8%">
                                                        <col style="width:17%">
                                                        <col *ngIf="isEditFixedFee" style="width:10%">
                                                        <col *ngIf="Is7216TemplateAvailable" style="width:15%">
                                                        <col style="width:8%">
                                                    </colgroup>
                                                </ng-template>

                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="height: 30px; padding: 0;text-align: center;">
                                                            Child Client ID
                                                        </th>
                                                        <th style="height: 30px; padding: 0; text-align: center;">
                                                            Child Client Name
                                                        </th>
                                                        <th *ngIf="isEditFixedFee"
                                                            style="height: 30px; padding: 0; text-align: center;">
                                                            Child Fees
                                                        </th>
                                                        <th *ngIf="Is7216TemplateAvailable"
                                                            style="height: 30px; padding: 0; text-align: center;">
                                                            Child 7216 Available
                                                        </th>
                                                        <th style="height: 30px; padding: 0;text-align: center;">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </ng-template>

                                                <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                                                    <tr style="height: 30px;"
                                                        [formGroup]="childEntitiesControls[rowIndex]">
                                                        <td style="padding: 0;text-align: center;">
                                                            {{
                                                            childEntitiesControls[rowIndex].get('ChildClientId').value
                                                            }}
                                                        </td>
                                                        <td
                                                            style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                            {{
                                                            childEntitiesControls[rowIndex].get('ChildClientName').value
                                                            }}
                                                        </td>
                                                        <td *ngIf="isEditFixedFee"
                                                            style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                            {{
                                                            childEntitiesControls[rowIndex].get('ChildFees').value
                                                            }}
                                                        </td>
                                                        <td *ngIf="Is7216TemplateAvailable"
                                                            style="padding: -1px; word-break: break-word; overflow-wrap: break-word; text-align: center;">
                                                            <div class="form-check form-check-inline">
                                                                <input type="radio" id="inlineRadioYes{{rowIndex}}"
                                                                    formControlName="Child7216Available" [value]="true"
                                                                    class="form-check-input" />
                                                                <label for="inlineRadioYes{{rowIndex}}"
                                                                    class="form-check-label">Yes</label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input type="radio" id="inlineRadioNo{{rowIndex}}"
                                                                    formControlName="Child7216Available" [value]="false"
                                                                    class="form-check-input" />
                                                                <label for="inlineRadioNo{{rowIndex}}"
                                                                    class="form-check-label">No</label>
                                                            </div>
                                                        </td>
                                                        <td style="padding: 0;text-align: center;"
                                                            class="text-center align-middle">
                                                            <p class="m-0">
                                                                <button class="bg-transparent mx-1 link-color"
                                                                    data-bs-toggle="modal" data-bs-target="#edit-field"
                                                                    pTooltip="Edit"
                                                                    (click)="editChildClientDetails(childEntitiesControls[rowIndex].controls,rowIndex)"><img
                                                                        src="./assets/images/edit.png" alt="Edit-icon">
                                                                </button>
                                                                <button class="bg-transparent mx-1 link-color"
                                                                    pTooltip="Delete" tooltipPosition="top"
                                                                    (click)="onRemoveEntityOptions(rowIndex)">
                                                                    <img src="./assets/images/delete-icon.png"
                                                                        alt="Delete-icon">
                                                                </button>
                                                            </p>

                                                        </td>
                                                    </tr>
                                                </ng-template>

                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="4" style="text-align: center;">No
                                                            ChildEntities found</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row" id="officedetails">
                            <span class="d-block mb-2 fw-bold">3.Office Details:</span>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" name="PartnerID" id="PartnerID" required
                                        class="form-control" id="{{this.PartnerFieldId}}" placeholder="Partner ID"
                                        [(ngModel)]="this.PartnerValues.PartnerId" readonly
                                        [formControl]="StaticFieldsForm.controls['PartnerID']">

                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2 ">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner Name
                                        <span class="err-color">*</span></label>
                                    <ng-select [(ngModel)]="this.PartnerValues.PartnerName"
                                        id="{{this.PartnerNameFieldId}}" [items]="partnerNameList"
                                        formControlName="PartnerName" (change)="onPartnerSelected($event)"
                                        bindLabel="FirstName" bindValue="FirstName" [searchable]="true"
                                        [clearable]="false" placeholder="Select Partner">
                                    </ng-select>

                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficeFieldId}}" placeholder="Office ID" name="OfficeID"
                                        [(ngModel)]="this.PartnerValues.OfficeId" readonly
                                        [formControl]="StaticFieldsForm.controls['OfficeID']">

                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Name
                                        <span class="err-color">*</span></label>
                                    <ng-select [(ngModel)]="this.PartnerValues.OfficeName" [items]="officeDetailsList"
                                        id="{{this.OfficeNameFieldId}}" formControlName="OfficeName"
                                        (change)="onOfficeSelected($event)" bindLabel="Name" bindValue="Name"
                                        [searchable]="true" [clearable]="false" placeholder="Select Partner">
                                    </ng-select>

                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line1
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeAddressLine1}}" placeholder="Office Address Line1"
                                        name="OfficeAddressLine1" [(ngModel)]="this.PartnerValues.OfficeAddressLine1"
                                        [formControl]="StaticFieldsForm.controls['OfficeAddressLine1']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line2
                                    </label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeAddressLine2}}" placeholder="Office Address Line2"
                                        name="OfficeAddressLine2" [(ngModel)]="this.PartnerValues.OfficeAddressLine2"
                                        [formControl]="StaticFieldsForm.controls['OfficeAddressLine2']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line3
                                    </label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeAddressLine3}}" placeholder="Office Address Line3"
                                        name="OfficeAddressLine3" [(ngModel)]="this.PartnerValues.OfficeAddressLine3"
                                        [formControl]="StaticFieldsForm.controls['OfficeAddressLine3']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office City
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeCityFieldId}}" placeholder="Office City" name="OfficeCity"
                                        [(ngModel)]="this.PartnerValues.City"
                                        [formControl]="StaticFieldsForm.controls['OfficeCity']">

                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office State
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeStateFieldId}}" placeholder="Office State" name="OfficeState"
                                        [(ngModel)]="this.PartnerValues.State"
                                        [formControl]="StaticFieldsForm.controls['OfficeState']">

                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Zip
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeZipCodeFieldId}}" placeholder="Office Zip" name="OfficeZip"
                                        [(ngModel)]="this.PartnerValues.ZipCode"
                                        [formControl]="StaticFieldsForm.controls['OfficeZip']">

                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Phone
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficePhoneNumberFieldId}}" placeholder="Office Phone"
                                        name="OfficePhone" [(ngModel)]="this.PartnerValues.PhoneNumber"
                                        [formControl]="StaticFieldsForm.controls['OfficePhone']">

                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Jurisdiction
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.jurisdictionFieldId}}" placeholder="Jurisdiction" name="Jurisdiction"
                                        [(ngModel)]="this.PartnerValues.jurisdiction"
                                        [formControl]="StaticFieldsForm.controls['Jurisdiction']">

                                </div>
                            </div>

                        </div>
                        <hr>
                        <div>
                            <span class="d-block mb-2 fw-bold">4.Other Details:</span>
                            <div class="row">

                                <!-- 7216 available -->
                                <div class="col-md-6 col-lg-3 mb-2" *ngIf="Template7216IsAvailable">
                                    <label for="" class="control-label mb-1">Parent 7216 Available?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="editField7216available"
                                                (change)="on7216OnItemChange($event.target.value)"
                                                formControlName="editField7216available" value="true"
                                                id="7216inlineRadio1" class="form-check-input">
                                            <label for="7216inlineRadio1" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="editField7216available"
                                                (change)="on7216OnItemChange($event.target.value)"
                                                formControlName="editField7216available" value="false"
                                                id="7216inlineRadio2" class="form-check-input"><label
                                                for="7216inlineRadio2" class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-12 col-md-6 col-lg-2">
                                    <label for="" class="control-label mb-1">E-Signing?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question ">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="inlineRadio5" name="Esigning"
                                                formControlName="Esigning" (change)="onItemChange($event.target.value)"
                                                value="true" class="form-check-input">
                                            <label for="inlineRadio5" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Esigning" id="inlineRadio6"
                                                formControlName="Esigning" (change)="onItemChange($event.target.value)"
                                                value="false" class="form-check-input"><label for="inlineRadio6"
                                                class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Template name
                                            <span class="err-color">*</span></label>
                                        <input type="text" autocomplete="off" class="form-control" id="templateName"
                                            placeholder="template name" name="templateName" readonly
                                            [formControl]="StaticFieldsForm.controls['TemplateName']"
                                            [(ngModel)]="this.PartnerValues.TemplateName">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2">
                                    <div class="mb-2 ">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Return Type Code
                                            <span class="err-color">*</span></label>
                                        <input type="text" autocomplete="off" class="form-control" id="3"
                                            placeholder="ReturnTypeCode" name="ReturnTypeCode" readonly
                                            [formControl]="StaticFieldsForm.controls['ReturnTypeCode']"
                                            [(ngModel)]="this.returntypecodebind">

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                            <button type="button" (click)="editFieldscloseModal()"
                                class="btn p-btn-outline">Close</button>
                            <button type="button" (click)="updateFields()" class="btn p-btn ms-3">Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal" tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayAddAttachment}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable upload-attachment">
        <div class="modal-content p-4" id="add-attachment">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0 mb-md-2">Upload Attachments
                    <span class="fs-12 label-color fw-regular">(Maximum of “5” attachments can be
                        added)</span>
                </h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeAttachmentPopup()"></button>
            </div>
            <div class="d-inline-flex">
                <span class="err-color">Note:</span>
                <ul class="ps-4">
                    <li class="label-color">Based on the attachment type (Letter or Email), System will
                        attach the attachments with letter or email.
                    </li>
                    <li class="label-color">If the attachment is marked as Letter, then it will be attached
                        with the letter.

                    </li>
                    <li class="label-color">If the attachment is marked as Email, then it will be attached
                        with the signing email and not with the letter.

                    </li>
                </ul>
            </div>
            <div class="modal-body p-0">
                <div class="p-2 border rounded h-100">

                    <div class="files-list py-3">
                        <table class="table new-implement-attachment">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th class="table-header-w-200">Name</th>
                                    <th>Level</th>
                                    <th>Attach with</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of staticRows; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td class="table-cell-ellipse" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="{{ uploadedFiles[i]?.Filename || '' }}">{{ uploadedFiles[i]?.Filename ||
                                        '' }}</td>
                                    <td class="table-cell-ellipse">{{ uploadedFiles[i]?.Filename ?
                                        uploadedFiles[i].Level : '' }}</td>

                                    <td>
                                        <span>
                                            <label *ngIf="uploadedFiles[i]?.Filename " class="mx-2">
                                                <input type="radio" name="AttachWith{{i}}"
                                                    [(ngModel)]="uploadedFiles[i].AttachWith" value="Email"
                                                    [disabled]="emailattachwith" class="form-check-input"> Email
                                            </label>
                                            <label *ngIf="uploadedFiles[i]?.Filename " class="mx-2">
                                                <input type="radio" name="AttachWith{{i}}"
                                                    [(ngModel)]="uploadedFiles[i].AttachWith" value="Letter"
                                                    [disabled]="Attachcheckwith" class="form-check-input"> Letter
                                            </label></span>


                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="position-relative file-upload">
                                                <input type="file" id="upload-btn-{{i}}" class="visually-hidden"
                                                    (change)="onFileSelected($event, i)" accept=".pdf"
                                                    [disabled]="uploadedFiles[i]?.Filename">

                                                <!-- Label for input file element, which acts as the clickable area -->
                                                <label for="upload-btn-{{i}}" class="uploadLink"
                                                    style="margin-right: 7px;">
                                                    <i class="fa fa-plus-circle" aria-hidden="true"
                                                        [ngClass]="{'disabled-icon': uploadedFiles[i]?.Filename, 'fa-plus-circle': !uploadedFiles[i]?.Filename}"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Add">
                                                    </i>
                                                </label>
                                            </div>
                                            <ng-container *ngIf="uploadedFiles[i]?.Filename">
                                                <a class="link-color text-decoration-none fw-medium"
                                                    (click)="uploadedFiles[i] && openAttachmentPreview(uploadedFiles[i])"
                                                    style="margin-right: 10px;">
                                                    <i class="fas fa-eye" aria-hidden="true" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Preview"></i>
                                                </a>
                                                <i class="fa fa-trash" aria-hidden="true"
                                                    (click)="uploadedFiles[i] && removeAttachment(i)"
                                                    style="cursor:pointer;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Delete"></i>
                                            </ng-container>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <p class="note" style="margin-right: 172px;"><i
                        class="fa-solid fa-triangle-exclamation "></i>&nbsp;<b>Note:
                        <span class="text-danger"> Do not</span> send any
                        PDFs with sensitive
                        information</b></p>
                <div>
                    <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal"
                        (click)="closeAttachmentPopup()">Close</button>
                    <!-- <button type="button" id="submitFileUpload" class="btn p-btn"
                        (click)="submitUploadedFile()">Submit</button> -->
                    <button type="button" id="submitFileUpload" class="btn p-btn"
                        (click)="submitBulkUploadedFile()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" tabindex="-1" id="attachmentsuccessmodal" [ngStyle]="{'display':displaySuccessAttachmentModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="attachmentsuccess">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessAttachmentModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <!-- <p class="fs-16">{{successMessage}}<br>Client ID:{{ successBlockcontentClientID}}</p> -->
                <p class="fs-16">{{successMessage}}</p>
                <p *ngIf="emailattachwith" style="word-wrap:break-word;text-align:center;">
                    Client ID : {{successAttachmentClientId}}
                </p>
                <button id="successOk" type="button" (click)="closeSuccessAttachmentModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreviewdocument}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closeAttachmentPreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <pdf-viewer class="customtaxlayerremove" #docPdfViewer [src]="this.pdfsrcdoc"
                                        [stick-to-page]="true" [show-borders]="true" [render-text]="true"
                                        style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                    </pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal -->
<div class="modal" tabindex="-1" id="fielderror" [ngStyle]="{'display':displayFieldErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="fielderrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeFieldErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" style="word-wrap:break-word;text-align: center;">
                    <span *ngIf="this.emptyFieldarr!=''" class="d-block text-center">Please provide input to
                        mandatory
                        field(s) - <span style="word-wrap:break-word;">{{emptyFieldarr}}</span>
                    </span><br>
                    <span class="d-block">{{clientiderrormessage}}</span>
                    <span class="d-block">{{signatorybotherrormessage}}</span>
                    <span class="d-block">{{signatoryspouseerrormessage}}</span>
                    <span class="d-block">{{signatorymailerrormessage}}</span>
                    <span class="d-block">{{feesErrorMessage}}</span>
                </p>
                <button type="button" id="fielderrorOk" (click)="closeFieldErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Edit Paragraph modal Start-->

<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayEditblockpreviewquestion}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content" id="viewEditParatemplate">
            <!-- Modal Header -->
            <div class="modal-header border-0 px-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="d-flex align-items-center h-100 px-2">
                                <h6 class="text-dark fw-bold m-0">Edit Paragraphs</h6>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 px-2">
                            <div class='text-end'>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" alt="close" (click)="closeblockpreviewquestion()"></button>
                            </div>
                            <div class="d-flex justify-content-between align align-items-center flex-wrap">
                                <div *ngIf="!notToShowClientIdClientName">
                                    <p class="fw-bold mb-1">Client ID: <span class="fw-medium px-2">
                                            {{this.clientId}}</span>
                                    </p>
                                    <p class="fw-bold mb-1">Client Name: <span class="fw-medium px-2">
                                            {{this.ClientName}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body" #EditParagraphScrollPosition>
                <div class="container-fluid">


                    <div class="col-md-12 ">
                        <div class="template_preview_panel">
                            <div class="preview_body h-100">
                                <div class="blockinner edit_preview new_height_align" #blockinner id="blockinner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">

                <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                    <button type="button" (click)="closeblockpreviewquestion()" class="btn p-btn-outline">Close</button>
                    <button type="button" id="submitEditPara" (click)="bulkEditParagraphSubmit()"
                        class="btn p-btn">Submit</button>

                </div>

            </div>
        </div>
    </div>
</div>

<div id="viewletter" class="modal" [class.shadow-popup]="thirdPopupOpen" tabindex="-1" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayCkeditor}">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0 p-3 pb-0">
                <!-- <button type="button" (click)="closeeditor()" class="close mx-3 mt-2" data-dismiss="modal">×</button> -->
                <button type="button" class="btn-close shadow-none" (click)="closeeditor()" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="m-0">
                                Block Name:
                                <span class="fw-bold"> {{ this.editjsonvalue.BlockName }}</span>
                            </p>
                        </div>
                    </div>

                    <div class=" row no-gutters">
                        <div class="col-12 col-sm-12 col-md-12 mt-3" #editornew>
                            <div class=" edit_content_preview">
                                <ckeditor [(data)]="this.editjsonvalue.Content" tagName="textarea"
                                    bodyClass='document-editor' [config]="config" (ready)="onReady( $event)"
                                    (change)="onChangeevent( $event )" (focus)="onFocus( $event )" (key)="onkey($event)"
                                    (blur)="onBlur( $event )" (paste)="onPaste( $event )" (keyup)="onkeyDown($event)"
                                    (afterPaste)="onAfterPaste( $event )" (dragStart)="( $event )"
                                    (dragEnd)="onDragEnd( $event )" (drop)="onDrop( $event )"> </ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0">
                <form>
                    <div class="form-group">
                        <!-- onDragStart  -->
                        <div class="text-end bg-white me-md-3">
                            <button type=" button" (click)="closeeditor()" class="btn p-btn-outline">Cancel</button>
                            <button type="button" (click)="updateblockcontent()" class="btn p-btn ms-3">Update</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<!-- Edit Paragraph modal End-->


<!-- Success Modal for Blocks-->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModalblocks}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="SuccessModalblocks">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModalblocks()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <!-- <p class="fs-16">{{successMessage}}<br>Client ID:{{ successBlockcontentClientID}}</p> -->
                <p class="fs-16">{{successMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">Client ID : {{successBlockcontentClientID}}</p>
                <button id="successBlocksOk" type="button" (click)="closeSuccessModalblocks()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Bulk Letter List choose paragraph modal -->
<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayActionsBulkListChooseParaModal}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content" id="viewChooseTemplate">
            <!-- Modal Header -->
            <div class="modal-header border-0 px-3 py-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="d-flex align-items-center h-100">
                                <h6 class="text-dark fw-bold m-0">Choose Paragraphs</h6>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 px-2">
                            <div class='text-end'>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" alt="close"
                                    (click)="closeActionsBulkListChooseParaModal()"></button>
                            </div>
                            <div class="d-flex justify-content-between align align-items-center flex-wrap">
                                <div>
                                    <p class="fw-bold mb-1">Client ID: <span class="fw-medium px-2">
                                            {{this.clientId}}</span>
                                    </p>
                                    <p class="fw-bold mb-1">Client Name: <span class="fw-medium px-2">
                                            {{this.ClientName}}</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="border h-100 para">
                                <div class="template_preview_panel">
                                    <div class="preview_header">
                                        <h5 class="text-dark fw-bold fs-6">Choose Paragraphs</h5>
                                    </div>
                                    <div class="preview_body h-100 p-3 preview_body_scroll"
                                        #BulklistchooseParagraphScrollPosition>
                                        <div id="previewquestionbulkblock" #previewquestionbulkblock
                                            class=" new_height_align">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="border h-100">
                                <div class="template_preview_panel"
                                    [ngClass]="togglefullscreen ? 'panel-fullscreen' : ''">
                                    <div class="preview_header">
                                        <h5 class="text-dark fw-bold fs-6">Letter Preview</h5>
                                    </div>
                                    <div class="preview_body h-100 preview_body_scroll"
                                        #BulklistChooseParagraphLetterPreviewScrollPosition>
                                        <div id="previewareabulkblock" #previewareabulkblock
                                            class="preview_inner new_height_align  p-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">

                <div>
                    <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                        <button type="button" (click)="closeActionsBulkListChooseParaModal()"
                            class="btn p-btn-outline">Close</button>
                        <button type="button" id="submitQuestionBulklistBlocks"
                            (click)="ActionsQuestionsBlocksubmitForBulkList()" class="btn p-btn">Submit</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- Accordian 2 next button error modal -->
<div class="modal" tabindex="-1" id="nexterror" [ngStyle]="{'display':displayNextErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayNextErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeNextErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <!-- <p class="fs-16" [innerHTML]="errorMessage"></p> -->
                <p class="fs-16">{{errorMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">{{ClientIdErrors}}</p>
                <button id="nexterrorOk" type="button" (click)="closeNextErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- CK edit Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayCKeditorErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="commonErrorCKeditor">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeCKeditorErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" [innerHTML]="errorMessage"></p>
                <button type="button" id="okCommonError" (click)="closeCKeditorErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Error FormEdit7216 Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':FE7216ErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="FE7216ErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- UpdateClient Error Modal -->
<div class="modal" tabindex="-1" id="updateclienterror" [ngStyle]="{'display':displayUpdateClientErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayUpdateClientErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeUpdateClientErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" [innerHTML]="updateClientErrorMessage"></p>
                <button id="commonerrorOk" type="button" (click)="closeUpdateClientErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal blocks-->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModalblocks}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="errorModalBlocks">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModalblocks()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">

                <p class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}</p>
                <button type="button" id="Okerror" (click)="closeErrorModalblocks()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Add Client Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-add-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayaddclient}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content p-4" style="min-height: 300px;" id="bulk-letter-add-field">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Add New Client</h5>
                <button type="button" class="btn-close shadow-none" (click)="addclientscloseModal()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <p class="m-0"><span class="err-color">Note: </span>All the fields are mandatory</p>
            <div class="modal-body" #addClientScrollPosition>
                <div class="p-3 px-0 border rounded">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="mb-2 mb-md-3 mb-lg-4 px-lg-4">
                                <label for="exampleFormControlInput1" class="form-label mb-1">Client Details
                                </label>
                                <div class="mt-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="addClientOptions"
                                            id="add-existing-client-radio" value="add-existing-client"
                                            [checked]="addExistingClientRadioButton" [(ngModel)]="addClientOptions"
                                            (change)="onAddClientSearchOptionChange($event)">
                                        <label class="form-check-label" for="add-existing-client-radio">Select Existing
                                            Client</label>
                                    </div>
                                    <div class="form-check form-check-inline ms-lg-3">
                                        <input class="form-check-input" type="radio" name="addClientOptions"
                                            [checked]="addNewClientRadioButton" id="add-new-client-radio"
                                            value="add-new-client" [(ngModel)]="addClientOptions"
                                            (change)="onAddClientSearchOptionChange($event)">
                                        <label class="form-check-label" for="add-new-client-radio">Add New
                                            Client</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-4" [hidden]="!addExistingClientRadioButton"
                            [formGroup]="addExistingClientForm">
                            <div class="mb-2 mb-md-3 mb-lg-4">
                                <label for="exampleFormControlInput1" class="form-label mb-1">Client ID/Name
                                    <span class="err-color">*</span></label>
                                <ng-select class="Dropdown-show-over-modal" formControlName="ClientIdName"
                                    [(ngModel)]="selectedClientData" [items]="ClientIdNameList" bindLabel="ClientName"
                                    [searchable]="true" [clearable]="true" [virtualScroll]="true" [bufferAmount]="100"
                                    placeholder="Select Clients" appendTo="body">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-2"></div>
                        <div class="col-12 col-md-12 col-lg-12" [hidden]="!addNewClientRadioButton">
                            <form id="staticfieldsform" [formGroup]="StaticFieldsClientForm">
                                <div class="p-3" id="ClientIfovalues">
                                    <div class="row mb-2">
                                        <span class="d-block mb-2 fw-bold">1.Client Details:</span>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div id="ClientId" class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Client
                                                    ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" name="ClientID" required
                                                    class="form-control" placeholder="Client ID"
                                                    [formControl]="StaticFieldsClientForm.controls['ClientID']">
                                                <ng-container *ngFor="let validation of validation_messages.ClientID">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsClientForm.get('ClientID').hasError(validation.type) && (StaticFieldsClientForm.get('ClientID').dirty || StaticFieldsClientForm.get('ClientID').touched)">
                                                        <label class="errorcolor" *ngIf="!condition"> {{
                                                            validation.message
                                                            }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Signatory
                                                    Email ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="SignatoryEmailId" placeholder="Signatory Email ID"
                                                    name="SignatoryEmailId"
                                                    [formControl]="StaticFieldsClientForm.controls['SignatoryEmailId']">
                                                <ng-container
                                                    *ngFor="let validation of validation_messages.SignatoryEmailId">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsClientForm.get('SignatoryEmailId').hasError(validation.type) && (StaticFieldsClientForm.get('SignatoryEmailId').dirty || StaticFieldsClientForm.get('SignatoryEmailId').touched)">

                                                        <label class="errorcolor" *ngIf="!condition">
                                                            {{ validation.message }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <!-- <div class="col-12 col-md-6 col-lg-3">
                                                <div class="mb-2">
                                                    <label for="exampleFormControlInput1" class="form-label mb-1">Spouse Email ID
                                                        </label>
                                                    <input type="text" autocomplete="off" class="form-control" id="SpouseEmailId"
                                                        placeholder="Spouse Email ID" name="SpouseEmailId"
                                                        [formControl]="StaticFieldsClientForm.controls['SpouseEmailId']">
                                                    <ng-container *ngFor="let validation of validation_messages.SpouseEmailId">
                                                        <div class="errorvl"
                                                            *ngIf="StaticFieldsClientForm.get('SpouseEmailId').hasError(validation.type) && (StaticFieldsClientForm.get('SpouseEmailId').dirty || StaticFieldsClientForm.get('SpouseEmailId').touched)">
                                                            <label class="errorcolor" *ngIf="!condition">
                                                                {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div> -->

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1 me-2">Tax
                                                    Year
                                                    <span class="err-color">*</span></label>
                                                <ng-multiselect-dropdown name="TaxYears" id="TaxYears"
                                                    class="myTaxYears" [placeholder]="'Select Tax Years'"
                                                    [(ngModel)]="selectedtaxyeareditfield"
                                                    [formControl]="StaticFieldsClientForm.controls['selectedclientYearsform']"
                                                    [settings]="dropdownSettings_taxyear" [data]=" this.taxYears">
                                                </ng-multiselect-dropdown>
                                                <ng-container *ngFor="let validation of validation_messages.TaxYears">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('TaxYears').hasError(validation.type) && (StaticFieldsForm.get('TaxYears').dirty || StaticFieldsForm.get('TaxYears').touched)">
                                                        <label class="errorcolor" *ngIf="!condition"> {{
                                                            validation.message
                                                            }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div id="addclientformbody" #addclientformbody class="row m-0 p-0">


                                        </div>
                                        <div id="addformbody" #addformbody class="row m-0 p-0">

                                        </div>


                                    </div>

                                    <div class="row">

                                        <span class="d-block mb-2 fw-bold">2.Child Entities:</span>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-3">
                                                    <label for="Childclientid" class="form-label mb-1">Child Client
                                                        ID</label>
                                                    <input type="text" autocomplete="off" class="form-control"
                                                        id="Childclientid" name="Childclientid"
                                                        [formControl]="StaticFieldsClientForm.controls['Childclientid']"
                                                        formControlName="Childclientid" placeholder="Child Client ID">
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.Childclientid">
                                                        <div class="errorvl"
                                                            *ngIf="StaticFieldsClientForm.get('Childclientid').hasError(validation.type) && 
                                                               (StaticFieldsClientForm.get('Childclientid').dirty || StaticFieldsClientForm.get('Childclientid').touched)">
                                                            <label class="errorcolor">{{ validation.message
                                                                }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="col-md-6 col-lg-3">
                                                    <label for="ChildEntities" class="form-label mb-1">Child Client
                                                        Name</label>
                                                    <input type="text" autocomplete="off" class="form-control"
                                                        id="ChildEntities" name="ChildEntities"
                                                        [formControl]="StaticFieldsClientForm.controls['ChildEntities']"
                                                        formControlName="ChildEntities" placeholder="Child Client Name">
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.ChildEntities">
                                                        <div class="errorvl"
                                                            *ngIf="StaticFieldsClientForm.get('ChildEntities').hasError(validation.type) && 
                                                               (StaticFieldsClientForm.get('ChildEntities').dirty || StaticFieldsClientForm.get('ChildEntities').touched)">
                                                            <label class="errorcolor">{{ validation.message
                                                                }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <!-- <div class="col-md-6 col-lg-3">
                                                    <label for="ChildFees" class="form-label mb-1">Child
                                                        Fees</label>
                                                    <input type="number" autocomplete="off" class="form-control"
                                                        id="ChildFees" name="ChildFees"
                                                        [formControl]="StaticFieldsClientForm.controls['ChildFees']"
                                                        formControlName="ChildFees" placeholder="Child Fees">
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.ChildFees">
                                                        <div class="errorvl"
                                                            *ngIf="StaticFieldsClientForm.get('ChildFees').hasError(validation.type) && 
                                                               (StaticFieldsClientForm.get('ChildFees').dirty || StaticFieldsClientForm.get('ChildFees').touched)">
                                                            <label class="errorcolor">{{ validation.message
                                                                }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div> -->
                                                <div class="col-md-6 col-lg-3">
                                                    <button type="button" #addeditclientbtn
                                                        class="btn p-btn mb-2 mb-md-0"
                                                        (click)="onEditchildEntityOptions()"
                                                        [disabled]="!ischildButtonDisabled()"
                                                        [hidden]="isAddClientBtnVisible"
                                                        style="margin-top:19px">Add</button>

                                                    <button type="button" #updateclientbtn
                                                        class="btn p-btn mb-2 mb-md-0"
                                                        (click)="onUpdateAddClientChildEntity()"
                                                        [disabled]="!ischildButtonDisabled()"
                                                        [hidden]="isUpdateClientBtnVisible"
                                                        style="margin-top:19px">Update</button>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" style="margin-top: 20px;">
                                                <div class="col-md-12 col-lg-7">
                                                    <div *ngIf="childEntitiesClientControls.length > 0">
                                                        <p-table #dt1 styleClass="p-datatable-gridlines"
                                                            dataKey="childID" [value]="childEntitiesClientValue"
                                                            [rows]="3" [resizableColumns]="true" sortMode="multiple"
                                                            [scrollable]="true" scrollHeight="1200px"
                                                            [showCurrentPageReport]="true" [paginator]="true"
                                                            [(first)]="childfirstpage"
                                                            currentPageReportTemplate="{first} to {last} of {totalRecords}">

                                                            <ng-template pTemplate="colgroup">
                                                                <colgroup>
                                                                    <col style="width:10%">
                                                                    <col style="width:17%">
                                                                    <!-- *ngIf="isbatchChoosePara"    -->
                                                                    <!-- <col style="width:10%"> -->
                                                                    <!-- *ngIf="overAll7216Button"    -->
                                                                    <!-- <col style="width:15%"> -->
                                                                    <col style="width:8%">
                                                                </colgroup>
                                                            </ng-template>

                                                            <ng-template pTemplate="header">
                                                                <tr>
                                                                    <th scope="col"
                                                                        style="height: 30px; padding: 0;text-align: center;">
                                                                        Child
                                                                        Client ID</th>
                                                                    <th scope="col"
                                                                        style="height: 30px; padding: 0; text-align: center;">
                                                                        Child
                                                                        Client Name</th>
                                                                    <!-- *ngIf="isbatchChoosePara"  -->
                                                                    <!-- <th scope="col"
                                                                        style="height: 30px; padding: 0; text-align: center;">
                                                                        Child Fees
                                                                    </th> -->
                                                                    <!-- *ngIf="overAll7216Button" -->
                                                                    <!-- <th scope="col"
                                                                        style="height: 30px; padding: 0; text-align: center;">
                                                                        Child
                                                                        7216 Available</th> -->
                                                                    <th scope="col"
                                                                        style="height: 30px; padding: 0;text-align: center;">
                                                                        Action
                                                                    </th>
                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="body" let-row
                                                                let-rowIndex="rowIndex">
                                                                <tr style="height: 30px;"
                                                                    [formGroup]="childEntitiesClientControls[rowIndex]">
                                                                    <td style="padding: 0;text-align: center;">
                                                                        {{
                                                                        childEntitiesClientControls[rowIndex].get('ChildClientId').value
                                                                        }}
                                                                    </td>
                                                                    <td
                                                                        style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                                        {{
                                                                        childEntitiesClientControls[rowIndex].get('ChildClientName').value
                                                                        }}
                                                                    </td>
                                                                    <!-- *ngIf="isbatchChoosePara" -->
                                                                    <!-- <td
                                                                        style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                                        {{
                                                                        childEntitiesClientControls[rowIndex].get('ChildFees').value
                                                                        }}
                                                                    </td> -->
                                                                    <!-- *ngIf="overAll7216Button" -->
                                                                    <!-- <td
                                                                        style="padding: -1px; word-break: break-word; overflow-wrap: break-word; text-align: center;">
                                                                        <div class="form-check form-check-inline">
                                                                            <input type="radio"
                                                                                id="inlineRadioYes{{rowIndex}}"
                                                                                formControlName="Child7216Available"
                                                                                [value]="true"
                                                                                class="form-check-input" />
                                                                            <label for="inlineRadioYes{{rowIndex}}"
                                                                                class="form-check-label">Yes</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input type="radio"
                                                                                id="inlineRadioNo{{rowIndex}}"
                                                                                formControlName="Child7216Available"
                                                                                [value]="false"
                                                                                class="form-check-input" />
                                                                            <label for="inlineRadioNo{{rowIndex}}"
                                                                                class="form-check-label">No</label>
                                                                        </div>
                                                                    </td> -->
                                                                    <td style="padding: 0;text-align: center;"
                                                                        class="text-center align-middle">
                                                                        <p class="m-0">
                                                                            <button
                                                                                class="bg-transparent mx-1 link-color"
                                                                                pTooltip="Edit"
                                                                                (click)="editAddClientChildDetails(childEntitiesClientControls[rowIndex].controls,rowIndex)"><img
                                                                                    src="./assets/images/edit.png"
                                                                                    alt="Edit-icon">
                                                                            </button>
                                                                            <button
                                                                                class="bg-transparent mx-1 link-color"
                                                                                pTooltip="Delete" tooltipPosition="top"
                                                                                (click)="onEditRemoveEntityOptions(rowIndex)">
                                                                                <img src="./assets/images/delete-icon.png"
                                                                                    alt="Delete-icon">
                                                                            </button>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="4" style="text-align: center;">No
                                                                        ChildEntities
                                                                        found</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row" id="officeadddetails">
                                        <span class="d-block mb-2 fw-bold">3.Office Details:</span>

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Partner ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" name="PartnerID" id="PartnerId"
                                                    required class="form-control" placeholder="Partner ID" readonly
                                                    [formControl]="StaticFieldsClientForm.controls['PartnerID']">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2 ">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Partner
                                                    Name
                                                    <span class="err-color">*</span></label>

                                                <ng-select [(ngModel)]="this.PartnerValues.PartnerName"
                                                    id="{{this.PartnerNameFieldId}}" [items]="partnerNameList"
                                                    [formControl]="StaticFieldsClientForm.controls['PartnerName']"
                                                    (change)="addClientPartnerSelected($event)" bindLabel="FirstName"
                                                    bindValue="FirstName" [searchable]="true" [clearable]="false"
                                                    placeholder="Select Partner">
                                                </ng-select>


                                            </div>
                                        </div>


                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" id="OfficeId"
                                                    placeholder="Office ID" name="OfficeID" readonly
                                                    [formControl]="StaticFieldsClientForm.controls['OfficeID']">

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Name
                                                    <span class="err-color">*</span></label>
                                                <ng-select [(ngModel)]="this.PartnerValues.OfficeName" id="Officename"
                                                    [items]="officeDetailsList"
                                                    [formControl]="StaticFieldsClientForm.controls['OfficeName']"
                                                    (change)="addClientOfficeChange($event)" bindLabel="Name"
                                                    bindValue="Name" [searchable]="true" [clearable]="false"
                                                    placeholder="Select Office">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Address
                                                    Line1
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="OfficeAddressLine1" placeholder="Office Address Line1"
                                                    name="OfficeAddressLine1" readonly
                                                    [formControl]="StaticFieldsClientForm.controls['OfficeAddressLine1']">

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Address
                                                    Line2
                                                </label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="OfficeAddressLine2" placeholder="Office Address Line2"
                                                    name="OfficeAddressLine2" readonly
                                                    [formControl]="StaticFieldsClientForm.controls['OfficeAddressLine2']">

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Address
                                                    Line3
                                                </label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="OfficeAddressLine3" placeholder="Office Address Line3"
                                                    name="OfficeAddressLine3" readonly
                                                    [formControl]="StaticFieldsClientForm.controls['OfficeAddressLine3']">

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    City
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="Officecity" placeholder="Office City" name="OfficeCity" readonly
                                                    [formControl]="StaticFieldsClientForm.controls['OfficeCity']">

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    State
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="Officestate" placeholder="Office State" name="OfficeState"
                                                    readonly
                                                    [formControl]="StaticFieldsClientForm.controls['OfficeState']">

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Zip
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="Officezip" placeholder="Office Zip" name="OfficeZip" readonly
                                                    [formControl]="StaticFieldsClientForm.controls['OfficeZip']">

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Phone
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="Officephone" placeholder="Office Phone" name="OfficePhone"
                                                    readonly
                                                    [formControl]="StaticFieldsClientForm.controls['OfficePhone']">

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1"
                                                    class="form-label mb-1">Jurisdiction
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="Jurisdiction" placeholder="Jurisdiction" name="Jurisdiction"
                                                    readonly
                                                    [formControl]="StaticFieldsClientForm.controls['Jurisdiction']">

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pb-0 border-0">
                <div class=" text-end bg-white">
                    <button type="button" (click)="addclientscloseModal()" class="btn p-btn-outline">Close</button>
                    <button type="button" (click)="addclient()" class="btn p-btn ms-3">Add</button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- error modal excisting add client -->
<div class="modal" tabindex="-1" id="addexistingclienterror"
    [ngStyle]="{'display':displayErrorModalAddExsistingClient}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorAddExisitngClientModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16"> {{errorMessage}}</p>
                <button type="button" id="addexistingclienterrorOk" (click)="closeErrorAddExisitngClientModal()"
                    class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal add duplicate client -->
<div class="modal" tabindex="-1" id="clienterror" [ngStyle]="{'display':displaynewClientErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="clienterrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closenewClientErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="s-text fw-medium mb-0">Already same Client details are available in this batch, please provide
                    new client details and then submit</p>

                <button type="button" id="clienterrorOk" (click)="closenewClientErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>