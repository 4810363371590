import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { configData } from 'src/configData';
import * as Msal from "msal";


@Injectable({
  providedIn: 'root'
})
export class DataService {
  issRefresh: string = 'false';
  loginRequest: any;
  commonHeader: any
  commonHeadermulitipart: any
  authToken: any
  private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor() {

    if (sessionStorage.length == 0) {
      this.ifSessionIsZero();
    }
    else if (sessionStorage.length > 0) {
      var accessToken = sessionStorage.getItem("msal.idtoken");
      if (accessToken == null) {
        setTimeout(() => {
          this.refreshtoken();
        }, 5000);
      }
      //var accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJkNGQwNmI1MS0yODMxLTRiNWMtYmMxMy03MTM2ZWQwNTk5MmIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vMmU1N2FhZTItNGVjZi00ZjA5LTk0NGUtMzM5NThjMjZhYTdiL3YyLjAiLCJpYXQiOjE2OTM0ODU4ODEsIm5iZiI6MTY5MzQ4NTg4MSwiZXhwIjoxNjkzNDg5NzgxLCJhaW8iOiJBVlFBcS84VUFBQUFvc0RDeGVaNFZza0Jza05hajJvaVlidGJVdnk5TUp5YkRjYk56d0tpWUd2a01yc0J5VThlZFFTOW9kV2dXR08wbXhudHJJamJHZXVnZ0VHYmEralNsdTVPY014R1NwclNNc28yQTVSTFprUT0iLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC85M2Y5N2JjNS1jZWQ5LTRlMWMtODM1MC04NjkyZWQ5NmJkYTIvIiwibmFtZSI6IkhhcmlWaWduZXNoIiwibm9uY2UiOiJmMGNjMWM1NS01N2MzLTQ0MzgtOTkwOC0yOGJlZTI3NGJlODEiLCJvaWQiOiIyZmQwMDdhYS0yZjAzLTRmMmMtOWYzMC1lMzJiMmY2ZTBlYTAiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJoYXJpdmlnbmVzaC5zQGNoYW5nZXBvbmQuY29tIiwicmgiOiIwLkFTa0E0cXBYTHM5T0NVLVVUak9WakNhcWUxRnIwTlF4S0Z4THZCTnhOdTBGbVNzcEFDOC4iLCJzdWIiOiJIVkxmS29EN2tHNzdaZi1pVkRzTEV1TVhKT2E1aXJ6Rk9RdG42bjEzakNNIiwidGlkIjoiMmU1N2FhZTItNGVjZi00ZjA5LTk0NGUtMzM5NThjMjZhYTdiIiwidXRpIjoiUUVZTFNKMzVIMHFnNk5SbEFORG1BQSIsInZlciI6IjIuMCJ9.MvEGHwnIL1g21TrzLNkeoeD46T3vCGDy1qZSWtsiCRUdAgOviOtoN6nU0HIPbDpw0nNNaCc1BlKb0bR7A95gk0MxB1kNkclzqUklnYVLo03-BmUL0n3grNyKZTD58fCvCD8VPhEPn1uvTUNagbqy7wfWygt0frpmEAjfVpDNE0ZrTYIWdcG_0WRTFtmIvWso1_mHSo-s3KwQoV4q-PrxubsQTm4RHsR1PLzbocVV78EXZSA9XX59tJ-i6j1WTdUQ8JSd33cc2tohWRi8Qsymca9mrP-w4xjWYGQN5Z3fcoKE18yV3TVqxhiKoEYpVSEPzwfqoQ89UPQfpRqdbmIahQ";
      else if (accessToken) {
        this.setAccessToken(accessToken);
      }
    }
    this.getAccessToken().subscribe(token => {
      if (token) {
        this.getHeaders();
        this.postHeaders();
        this.postHeadersmultipart();
      }
    });
  }

  private adobePathURL = new BehaviorSubject('default message');
  adobePathURLData = this.adobePathURL.asObservable();


  passingAdobeURLToSigning(message: string) {
    this.adobePathURL.next(message)
  }

  private previewContent = new BehaviorSubject('default message');
  previewContentData = this.previewContent.asObservable();

  PreviewContentData(message: string) {
    this.previewContent.next(message)
  }

  private previewLetterHeadCon = new BehaviorSubject('default message');
  previewLetterHeadData = this.previewLetterHeadCon.asObservable();

  letterConWithHeader(message: string) {
    this.previewLetterHeadCon.next(message);
  }

  private getHeader = new BehaviorSubject('default message');
  getHeadService = this.getHeader.asObservable();

  commonHeaders() {
    // Getting the access token using the getAccessToken function
    this.getAccessToken().subscribe(token => {
      if (token) {
        this.authToken = token;
      }
    });
    let headersnew = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'json',
      'Authorization': 'bearer ' + this.authToken,
      'appversion': 'v3',
    });
    return headersnew;
  }

  commonHeadersmultipart() {
    // Getting the access token using the getAccessToken function
    this.getAccessToken().subscribe(token => {
      if (token) {
        this.authToken = token;
      }
    });
    let headersnew = new HttpHeaders({
      'Authorization': 'bearer ' + this.authToken,
      'appversion': 'v3'
    });
    return headersnew;
  }

  getHeaders() {
    this.commonHeader = this.commonHeaders();
    this.getHeader.next(this.commonHeader);
  }

  private postHeader = new BehaviorSubject('default message');
  postHeaderService = this.postHeader.asObservable();

  private postHeaderMultipart = new BehaviorSubject('default message');
  postHeaderServiceMultipart = this.postHeaderMultipart.asObservable();

  postHeaders() {
    this.commonHeader = this.commonHeaders();
    let options: any = {
      headers: this.commonHeader
    }
    this.postHeader.next(options);
  }

  postHeadersmultipart() {
    this.commonHeadermulitipart = this.commonHeadersmultipart();
    let optionsmulitipart: any = {
      headers: this.commonHeadermulitipart
    }
    this.postHeaderMultipart.next(optionsmulitipart);
  }
  setAccessToken(token: string) {
    this.accessTokenSubject.next(token);
  }
  getAccessToken(): Observable<string | null> {
    return this.accessTokenSubject.asObservable();
  }
  async refreshtoken() {
    const keys = Object.keys(sessionStorage).filter(x => x.indexOf('authority') > 0)
    keys.forEach(x => sessionStorage.removeItem(x))
    sessionStorage.removeItem("msal.idtoken");
    const msalConfig = configData.msalConfig;
    const clientId = msalConfig.auth.clientId;
    const authId = msalConfig.auth.authority;

    let clientApplication = new Msal.UserAgentApplication(msalConfig);
    this.loginRequest = {
      // scopes: ["User.Read","Group.Read.All"]
      scopes: ["d4d06b51-2831-4b5c-bc13-7136ed05992b"]

    }
    clientApplication.acquireTokenSilent(this.loginRequest)
      .then((response) => {
        console.log(response);
        if (response.idToken) {
          console.log(response.idToken.rawIdToken, "id_token acquired at: " + new Date().toString());
          localStorage.setItem("RefreshToken", response.idToken.rawIdToken);
          this.setAccessToken(response.idToken.rawIdToken);
          this.getHeaders();
          this.postHeaders();
          this.postHeadersmultipart();
          let accessToken = response.idToken;
          let AccountId = response.account.accountIdentifier;
        }
      })
      .catch(error => {
        console.log("silent token acquisition fails. acquiring token using redirect");
        console.log(error);
        // fallback to interaction when silent call fails
        return clientApplication.acquireTokenRedirect(this.loginRequest);
        // Check if the error is due to the required information missing
        // if (error.errorMessage.includes("User login is required. For silent calls, request must contain either sid or login_hint")) {
        //   // Handle the missing user information by initiating an interactive token acquisition.
        //   clientApplication.acquireTokenRedirect(this.loginRequest);
        //   console.log('hereee');
        // } else {
        //   // Handle other errors as needed.
        //   console.error("Error occurred during token acquisition:", error);
        // }
      });

  }

  private downloadContent = new BehaviorSubject('default message');
  downloadContentData = this.downloadContent.asObservable();

  DownloadContentData(message: string) {
    this.downloadContent.next(message)
  }

  //add attachment in template
  private uploadFileCon = new BehaviorSubject('default message');
  addAttachmentData = this.uploadFileCon.asObservable();

  uploadedFiles(message: string) {
    this.uploadFileCon.next(message);
  }
  ifSessionIsZero() {
    const msalConfig = configData.msalConfig;
    const clientId = msalConfig.auth.clientId;
    const authId = msalConfig.auth.authority;

    let clientApplication = new Msal.UserAgentApplication(msalConfig);

    const loginRequest = {
      scopes: ["User.Read", "Group.Read.All"]

    };
    localStorage.removeItem("Logouttrigered");
    // setTimeout(() => {
    // let MSRedirectMessage = localStorage.getItem("MSRedirectMessage");
    // if (MSRedirectMessage == null) {
    //this.percentageMessage = "Redirecting to Microsoft to authenticate...";
    // }
    clientApplication.loginRedirect(loginRequest);
    this.refreshtoken();
    // }, 1000);

  }
}
