import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheclearService {

  constructor() { }

  // Subject to communicate between components
  private displayErrorModalSubject = new Subject<string>();

  // Observable to listen for changes
  displayCommonWarningModal$ = this.displayErrorModalSubject.asObservable();

  // Method to trigger showing the error modal
  displayClearCacheWarningModal(message: string) {
    this.displayErrorModalSubject.next(message);
  }
}
