<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <div class="text-md-end mb-3 mb-lg-4">
                <button type="button" class="btn p-btn" data-bs-toggle="modal" data-bs-target="#add-questions"
                    (click)="addQuestionModal()">Add</button>
                <button #deletebutton (click)="deleteQuestionModal()" type="button" class="btn p-btn ms-2 ms-lg-3"
                    [disabled]="isButtonEnabled">Delete</button>
            </div>
            <section>
                <ngx-spinner size="medium" [type]="typeSelected">
                </ngx-spinner>
                <!-- data table -->
                <div class=" bg-white px-4 py-3 rounded">
                    <h5 class="highlight mb-lg-3 fs-18 fw-semibold">List of Questions</h5>
                    <!--data table -->
                    <div class="table-responsive">

                        <p-table #qt [rowHover]="false" [value]="questionValues" dataKey="id"
                            styleClass="p-datatable-gridlines" [resizableColumns]="true" sortMode="multiple"
                            [scrollable]="true" scrollHeight="1000px" [paginator]="true" [rows]="10"
                            [showCurrentPageReport]="true" [paginator]="true"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}">

                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col style="width:4%">
                                    <col style="width:7%">
                                    <col style="width:35%">
                                    <col style="width:18%">
                                    <col style="width:16%">
                                    <col style="width:17%">
                                    <col style="width:9%">
                                </colgroup>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th></th>
                                    <th pResizableColumn pSortableColumn="QuestionsId">ID <p-sortIcon
                                            field="QuestionId">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="QuestionText">Question Text<p-sortIcon
                                            field="QuestionText">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="ResponseType">Response Type<p-sortIcon
                                            field="ResponseType"></p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="Status">Status<p-sortIcon field="Status">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="ChangeNotes">Change Notes <p-sortIcon
                                            field="ChangeNotes"></p-sortIcon>
                                    </th>
                                    <th pResizableColumn><span>Action</span></th>
                                </tr>

                                <tr>
                                    <th class="text-center"><input type="checkbox" (change)="checkAllCheckBox($event)"
                                            [checked]="isAllCheckBoxChecked()" [disabled]="selectAllCheckBoxDisabled"
                                            style="width: 13px;" />
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'QuestionsId', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'QuestionText', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'ResponseType', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'StatusName', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'ChangeNotes', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-question>
                                <tr>
                                    <td class="text-center">
                                        <input type="checkbox" value="{{question.QuestionsId}}"
                                            [(ngModel)]="question.checked"
                                            (change)="toggleSingleCheckbox(question.QuestionId,question.checked)">
                                    </td>
                                    <td>{{question.QuestionsId}}</td>
                                    <td>{{question.QuestionText}}</td>
                                    <td>{{question.ResponseType }}</td>
                                    <td style="width:10%">{{question.Status}}</td>
                                    <td>{{question.ChangeNotes}}</td>

                                    <td class="icon_image">
                                        <button class="bg-transparent mx-1" pTooltip="Edit"
                                            (click)="editQuestionModal(question.QuestionsId)" data-bs-toggle="modal"
                                            data-bs-target="#edit-question"><img src="./assets/images/edit.png"
                                                alt="Edit-icon">
                                        </button>
                                        <a for="upload-btn" class="link-color uploadLink text-decoration-none"
                                            (click)="editQuestionModal(question.QuestionsId)">
                                            Edit
                                        </a>
                                    </td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="12" style="text-align: center;">No
                                        Questions Found</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <!-- data table -->

                <!-- Add question Modal start here -->
                <div class="modal" tabindex="-1" id="add-questions" [ngStyle]="{'display':displayAddQuestion}"
                    [class.shadow-popup]="secondPopupOpen">
                    <div class="modal-dialog  modal-dialog-centered modal-lg">
                        <div class="modal-content p-4">
                            <div class="modal-header border-0 p-0">
                                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Add New Question </h5>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" (click)="CloseQuestionModal()"></button>
                            </div>
                            <p><span class="err-color">Note: </span>1. All the questions are mandatory.</p>
                            <p style="margin-left: 40px;">2. The question text will not automatically update in the templates if changes are made.</p>
                            <p style="margin-left: 40px;">3. Please arrange the updated questions and adjust the logic in the templates accordingly.</p>

                            <div class="modal-body p-0">
                                <div class="p-4 border rounded">
                                    <form class="form-horizontal" [formGroup]="createQuestionForm">
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="mb-2 mb-md-3 mb-lg-6">
                                                    <label for="questionid" class="form-label mb-1">ID <span
                                                            class="err-color">*</span></label>
                                                    <input type="text" id="questionid" placeholder="ID"
                                                        formControlName="QuestionID"
                                                        [formControl]="createQuestionForm.controls['QuestionID']"
                                                        autocomplete="off" class="form-control input-md"
                                                        value="{{count}}" disabled>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="mb-2 mb-md-3 mb-lg-6">
                                                    <label for="questionname" class="form-label mb-1">Question
                                                        Text
                                                        <span class="err-color">*</span></label>
                                                    <textarea formControlName="QuestionText"
                                                        placeholder="Enter Question" #nameit id="QuestionText"
                                                        [formControl]="createQuestionForm.controls['QuestionText']"
                                                        required type="text" class="form-control input-md" focusOnce>
                                                    </textarea>

                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.QuestionText">
                                                        <div class="errorvl errorvlfname "
                                                            *ngIf="createQuestionForm.get('QuestionText').hasError(validation.type) && (createQuestionForm.get('QuestionText').dirty || createQuestionForm.get('QuestionText').touched)">
                                                            <label class="errorcolor hide"> {{ validation.message
                                                                }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="mb-2 mb-md-3 mb-lg-6">
                                                    <label for="InputSources" class="form-label mb-1">Response Type
                                                        <span class="err-color">*</span></label>
                                                    <div class="response_question col-sm-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                formControlName="ResponseType" name="ResponseType"
                                                                id="inlineRadio1" value="RadioGroup" checked />
                                                            <label class="form-check-label" for="inlineRadio1">Radio
                                                                Group</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="ResponseType" formControlName="ResponseType"
                                                                id="inlineRadio2" value="Dropdown" />
                                                            <label class="form-check-label"
                                                                for="inlineRadio2">Dropdown</label>
                                                        </div>

                                                        <ng-container
                                                            *ngFor="let validation of validation_messages.ResponseType">
                                                            <div class="errorvl"
                                                                *ngIf="createQuestionForm.get('ResponseType').hasError(validation.type) && (createQuestionForm.get('ResponseType').dirty || createQuestionForm.get('ResponseType').touched)">
                                                                <label class="errorcolor"> {{ validation.message
                                                                    }}</label>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div>
                                                    <label class="control-label" for="status">Status<span
                                                            class="err-color">*</span></label>
                                                    <ng-select [items]="StatusValue" bindLabel="StatusName"
                                                        formControlName="Status" placeholder="Select Status"
                                                        [readonly]="isReadstatusOnly" [(ngModel)]="model.selectedStatus"
                                                        [searchable]="false" [clearable]="false">
                                                    </ng-select>
                                                    <ng-container *ngFor="let validation of validation_messages.Status">
                                                        <div class="errorvl"
                                                            *ngIf="createQuestionForm.get('Status').hasError(validation.type) && (createQuestionForm.get('Status').dirty || createQuestionForm.get('Status').touched)">
                                                            <label class="errorcolor">{{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="row form-group">
                                                    <label class="col-sm-12 control-label" for="">Response
                                                        Options<span class="err-color">*</span></label>
                                                    <div class="col-sm-8">
                                                        <input id="" name="" type="text" autocomplete="off"
                                                            [formControl]="createQuestionForm.controls['ResponseOption']"
                                                            placeholder="Response option"
                                                            class="form-control input-md" />
                                                        <ng-container>
                                                            <div
                                                                *ngFor="let validation of validation_messages.ResponseOption">
                                                                <div class="errorvl"
                                                                    *ngIf="createQuestionForm.get('ResponseOption').hasError(validation.type) && (createQuestionForm.get('ResponseOption').dirty || createQuestionForm.get('ResponseOption').touched)">
                                                                    <label class="errorcolor"> {{ validation.message
                                                                        }}</label>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-sm-4  pl-0">
                                                        <button type="button" (click)=" onAddResponseOptions()"
                                                            class="btn p-btn gap-2"
                                                            [disabled]="!createQuestionForm.controls['ResponseOption'].valid || createQuestionForm.controls['ResponseOption'].value=='' || createQuestionForm.controls['ResponseOption'].value== undefined"><i
                                                                class="fa fa-plus" aria-hidden="true"></i>Add</button>
                                                    </div>
                                                    <div class="col-sm-12 mt-2">
                                                        <div class="response_tag_wrapper"
                                                            *ngIf="addResponseOptions!= ''">
                                                            <p *ngFor="let addRes of addResponseOptions; let i=index;"
                                                                class="response_tag">{{addRes}}<a><i class="fa fa-times"
                                                                        (click)=" onRemoveResponseOptions(addRes)"
                                                                        aria-hidden="true"></i></a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label class="control-label" for="changenotes">Change
                                                        Notes<span class="err-color">*</span></label>
                                                    <div class="">
                                                        <textarea id="changenotes" formControlName="ChangeNotes"
                                                            name="changenotes" placeholder="Enter Notes"
                                                            class="form-control input-md"></textarea>
                                                        <ng-container
                                                            *ngFor="let validation of validation_messages.ChangeNotes">
                                                            <div class="errorvl"
                                                                *ngIf="createQuestionForm.get('ChangeNotes').hasError(validation.type) && (createQuestionForm.get('ChangeNotes').dirty || createQuestionForm.get('ChangeNotes').touched)">
                                                                <label class="errorcolor">{{ validation.message
                                                                    }}</label>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row justify-content-center">
                                            <div
                                                class="col-12 col-md-12 col-lg-4 d-flex justify-content-center align-items-center">
                                                <div class="mt-3 mt-md-4">
                                                    <button type="button" class="btn p-btn-outline"
                                                        (click)="onClearQuestion()">Clear</button>

                                                    <button type="button" class="btn p-btn ms-3"
                                                        [disabled]="!createQuestionForm.valid || addResponseOptions ==''"
                                                        (click)="saveQuestion()">Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Edit question Modal start here -->
                <div class="modal" tabindex="-1" id="edit-questions" [ngStyle]="{'display':displayEditQuestion}"
                    [class.shadow-popup]="secondPopupOpen">
                    <div class="modal-dialog  modal-dialog-centered modal-lg">
                        <div class="modal-content p-4">
                            <div class="modal-header border-0 p-0">
                                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Edit New Question </h5>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" (click)="CloseQuestionModal()"></button>
                            </div>
                            <p><span class="err-color">Note: </span>1. All the questions are mandatory.</p>
                            <p style="margin-left: 40px;">2. The question text will not automatically update in the templates if changes are made.</p>
                            <p style="margin-left: 40px;">3. Please arrange the updated questions and adjust the logic in the templates accordingly.</p>
                            <div class="modal-body p-0">
                                <div class="p-4 border rounded">
                                    <form class="form-horizontal" [formGroup]="editQuestionForm">
                                        <div class="row">
                                            <!-- Input field for Question ID -->
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="mb-2 mb-md-3 mb-lg-6">
                                                    <label for="questionid" class="form-label mb-1">ID <span
                                                            class="err-color">*</span></label>
                                                    <input type="text" id="questionid" placeholder="ID"
                                                        formControlName="QuestionID"
                                                        [formControl]="editQuestionForm.controls['QuestionID']"
                                                        autocomplete="off" class="form-control input-md" disabled>
                                                </div>
                                            </div>

                                            <!-- Textarea for Question Text -->
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="mb-2 mb-md-3 mb-lg-6">
                                                    <label for="questionname" class="form-label mb-1">Question Text
                                                        <span class="err-color">*</span></label>
                                                    <textarea formControlName="QuestionText"
                                                        [formControl]="editQuestionForm.controls['QuestionText']"
                                                        placeholder="Enter Question" class="form-control input-md">
                                                    </textarea>
                                                    <!-- Validation messages -->
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.QuestionText">
                                                        <div class="errorvl errorvlfname"
                                                            *ngIf="editQuestionForm.get('QuestionText').hasError(validation.type) && (editQuestionForm.get('QuestionText').dirty || editQuestionForm.get('QuestionText').touched)">
                                                            <label class="errorcolor">{{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <!-- Radio buttons for Response Type -->
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="mb-2 mb-md-3 mb-lg-6">
                                                    <label for="InputSources" class="form-label mb-1">Response Type
                                                        <span class="err-color">*</span></label>
                                                    <div class="response_question col-sm-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                [formControl]="editQuestionForm.controls['ResponseType']"
                                                                name="radio2" id="inlineRadio3" value="RadioGroup" />
                                                            <label class="form-check-label" for="inlineRadio3">Radio
                                                                Group</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio" name="radio2"
                                                                [formControl]="editQuestionForm.controls['ResponseType']"
                                                                id="inlineRadio4" value="Dropdown" />
                                                            <label class="form-check-label"
                                                                for="inlineRadio4">Dropdown</label>
                                                        </div>

                                                        <ng-container
                                                            *ngFor="let validation of validation_messages.ResponseType">
                                                            <div class="errorvl"
                                                                *ngIf="editQuestionForm.get('ResponseType').hasError(validation.type) && (editQuestionForm.get('ResponseType').dirty || editQuestionForm.get('ResponseType').touched)">
                                                                <label class="errorcolor"> {{ validation.message
                                                                    }}</label>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Status dropdown with ng-select -->
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div>
                                                    <label class="control-label" for="status">Status<span
                                                            class="err-color">*</span></label>
                                                    <ng-select [items]="StatusValue" bindLabel="StatusName"
                                                        formControlName="Status"
                                                        [formControl]="editQuestionForm.controls['Status']"
                                                        [readonly]="isReadstatusOnly" [searchable]="false"
                                                        [clearable]="false" [(ngModel)]="this.statusdata"
                                                        [(ngModel)]="model.selectedStatus">
                                                    </ng-select>

                                                    <!-- Validation messages -->
                                                    <ng-container *ngFor="let validation of validation_messages.Status">
                                                        <div class="errorvl"
                                                            *ngIf="editQuestionForm.get('Status').hasError(validation.type) && (editQuestionForm.get('Status').dirty || editQuestionForm.get('Status').touched)">
                                                            <label class="errorcolor">{{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Response Options input and additional logic -->
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="row form-group">
                                                    <label class="col-sm-12 control-label" for="">Response Options<span
                                                            class="err-color">*</span></label>
                                                    <div class="col-sm-8">
                                                        <input type="text" autocomplete="off"
                                                            formControlName="ResponseOption"
                                                            [formControl]="editQuestionForm.controls['ResponseOption']"
                                                            placeholder="Response option"
                                                            class="form-control input-md" />
                                                        <!-- Validation messages -->
                                                        <ng-container
                                                            *ngFor="let validation of validation_messages.ResponseOption">
                                                            <div class="errorvl"
                                                                *ngIf="editQuestionForm.get('ResponseOption').hasError(validation.type) && (editQuestionForm.get('ResponseOption').dirty || editQuestionForm.get('ResponseOption').touched)">
                                                                <label class="errorcolor">{{ validation.message
                                                                    }}</label>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <!-- Add button with validation check -->
                                                    <div class="col-sm-4  pl-0">
                                                        <button type="button" (click)="onEditResponseOptions()"
                                                            class="btn p-btn gap-2"
                                                            [disabled]="!editQuestionForm.get('ResponseOption').valid || editQuestionForm.controls['ResponseOption'].value == '' ||editQuestionForm.controls['ResponseOption'].value== undefined">
                                                            <i class="fa fa-plus" aria-hidden="true"></i> Add
                                                        </button>

                                                    </div>

                                                    <!-- Display added response options -->
                                                    <div class="col-sm-12 mt-2">
                                                        <div class="response_tag_wrapper" *ngIf="optionsarray!= ''"
                                                            #resptagreadonly>
                                                            <p *ngFor="let addRes of optionsarray; let i=index;"
                                                                class="response_tag">{{addRes}}<a><i class="fa fa-times"
                                                                        (click)=" onRemoveUpdateResponseOptions(addRes)"
                                                                        aria-hidden="true"></i></a></p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Change Notes textarea -->
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label class="control-label" for="changenotes">Change Notes<span
                                                            class="err-color">*</span></label>
                                                    <div class="">
                                                        <textarea id="changenotes" formControlName="ChangeNotes"
                                                            placeholder="Enter Notes"
                                                            class="form-control input-md"></textarea>
                                                        <!-- Validation messages -->
                                                        <ng-container
                                                            *ngFor="let validation of validation_messages.ChangeNotes">
                                                            <div class="errorvl"
                                                                *ngIf="editQuestionForm.get('ChangeNotes').hasError(validation.type) && (editQuestionForm.get('ChangeNotes').dirty || editQuestionForm.get('ChangeNotes').touched)">
                                                                <label class="errorcolor">{{ validation.message
                                                                    }}</label>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Form buttons -->
                                        <div class="row justify-content-center">
                                            <div
                                                class="col-12 col-md-12 col-lg-4 d-flex justify-content-center align-items-center">
                                                <div class="mt-3 mt-md-4">
                                                    <!-- Clear button -->
                                                    <button type="button" class="btn p-btn-outline"
                                                        (click)="CloseQuestionModal()">Cancel</button>

                                                    <!-- Save button with validation check -->

                                                    <button type="button"
                                                        [disabled]="!editQuestionForm.valid || optionsarray =='' "
                                                        (click)=" UpdateQuestion()"
                                                        class="btn p-btn ms-3">Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Success Modal -->
                <div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header success-modal">
                                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">
                                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                                <p class="fs-16">{{successMessage}}</p>
                                <button type="button" (click)="closeSuccessModal()" class="success-btn"
                                    data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Error Modal -->
                <div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header error-modal">
                                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">
                                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                                <p class="fs-16">{{errorMessage}}</p>
                                <button type="button" (click)="closeErrorModal()" class="error-btn"
                                    data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- warning Modal -->
                <div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header warning-modal">
                                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                                <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center">
                                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                                <p class="fs-16">{{warningMessage}}</p>
                                <button type="button" (click)="closeWarningModal()" class="warning-btn"
                                    data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Delete Modal start here -->
                <div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                                <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p class="s-text fw-medium mb-0">Are you sure want to delete the
                                    Question(s)?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                                    data-bs-dismiss="modal">No</button>
                                <button type="button" (click)="onDeleteQuestion()" class="btn p-btn ms-2">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </main>
    </div>
    <div [innerHTML]="trustedHtml"></div>
</section>