import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule, AbstractControl } from '@angular/forms';
import { Table } from 'primeng/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastService } from 'src/app/shared/toast.service';
import { ToastrService } from 'ngx-toastr';
import { FieldService } from 'src/app/services/field.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CacheclearService } from 'src/app/services/cacheclear.service';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.css']
})
export class FieldsComponent implements OnInit {
  selectedCar: number;
  selectedbike: number;

  //common variables
  typeSelected: string;
  ScreenName: string = 'Fields';
  displayAddField = "none";
  displayEditField = "none";
  trustedHtml: SafeHtml;
  Username: any;
  isFieldEditable: boolean = true;

  // Field Variables
  isCheckedDirectInput: boolean = true;
  isCheckedClientInfo: boolean = false;
  public validation_messages;
  selectedDatatype: any;
  model: any = {};
  public fieldnamecheck: any;
  editjsonvalue: any = {};
  keyupfieldValue: any | undefined;
  EditDirectInput: boolean;
  EditClientInfo: boolean;
  displayUpdateModal: any = 'none';
  fieldjsonvalue = {};
  public singleDeleteId: any;
  displayDatabaseFailure = "none";
  public totalDeleteId: any;
  public deletecheckvalidation: any;
  public fieldNamevalidation: any = [];
  newarray = [];
  statusdata: any;
  public fieldNameexist: any = [];
  fieldnameView: any;
  MDDColumName: any;
  isMDDVal: boolean = false;
  isDataType: any;
  isDirectInputDisabled: boolean = false;
  isClientInfoDisabled: boolean = false;

  //add field & edit field
  fieldNamesArray = [];
  fieldIdCount: number;
  newFeildId: number;
  createFieldForm: FormGroup;
  fieldEditForm: FormGroup;
  MDDtypeValue: any;
  StatusValue: any;
  DatatypeValue: any;
  selectedDatatypevalue: any;
  findDataType: any;
  fieldDataTypeId: any;
  MDDColoumnName: any;
  MDDcoloumnId: any;
  findMDDcoloumn: any;
  allMDDcoloumnArray: any;
  ClientInfo: any;
  formControlsToReset: any;
  fieldidmodel = {};
  viewFieldJsonArray: any;
  isReadOnlyDataType: boolean = false;
  isReadOnlyClientInfo: boolean = true;
  EditMDDLookup: any;
  isReadstatusOnly: boolean = false;
  viewClientinfo: any;
  viewDatatype: any;
  viewEditedStatus: any;
  viewDatatypeJson: any;
  viewClientinfoJson: any;
  selectedMDDtype: any;
  fieldUpdateJsonValue: any;
  selectedStatus: any;
  isFieldNameDisabled: boolean = false;
  selectAllCheckBoxDisabled: boolean = false;

  //delete
  public displayDeleteModal: any;
  deleteId: any;
  anyCheckboxSelected: boolean;
  public selectedFieldProduct: any;

  //list field
  isChecked: boolean;
  isButtonEnabled = true;
  fieldValues = [];

  //Modal variables
  displayLogout = "none";
  displayWarningModal = "none";
  warningMessage: string;
  displayErrorModal = "none";
  errorMessage: string;
  displaySuccessModal = "none";
  successMessage: string;
  secondPopupOpen: boolean = false;

  //view child

  @ViewChild('dt') table: Table;
  @ViewChild('maintoggledrop', { static: false }) maintoggledrop: ElementRef;
  @ViewChild("nameit", { static: false }) private elementRef: ElementRef;
  @ViewChild("del", { static: false }) del: ElementRef;


  constructor(public s: DomSanitizer, private renderer: Renderer2, private formbld: FormBuilder, private toast: ToastService, private toastr: ToastrService, public fieldservice: FieldService, public SpinnerService: NgxSpinnerService, private el: ElementRef,private CacheclearService: CacheclearService) {
    this.typeSelected = 'timer';
    this.createFieldForm = this.formbld.group({
      FieldID: new FormControl([
      ]),
      FieldName: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(50), Validators.required])],
      DisplayName: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(50), Validators.required])],
      DataType: ['', [Validators.required]],
      Direct: ['option1'],
      Clientdata: [''],
      ClientInfo: [''],
      Status: ['', [Validators.required]],
      ChangeNotes: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255), , this.minLengthWithoutLeadingSpaces(5)]],
    });

    this.fieldEditForm = this.formbld.group({
      FieldID: '',
      FieldName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      DisplayName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      DataType: '',
      ClientInfo: [''],
      Clientdata: [''],
      Status: ['', Validators.required],
      ChangeNotes: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255), , this.minLengthWithoutLeadingSpaces(5)]],
    });

    this.validation_messages = {
      'FieldID': [

      ],
      'FieldName': [
        { type: 'required', message: this.toast.NameError },
        { type: 'maxlength', message: this.toast.firstNameCharactersOnly },
        { type: 'minlength', message: this.toast.firstMinCharactersOnly },
      ],
      'DisplayName': [
        { type: 'required', message: this.toast.DisplayNameError },
        { type: 'maxlength', message: this.toast.displayNameCharactersOnly },
        { type: 'minlength', message: this.toast.displayMinCharactersOnly },
      ],
      'Status': [
        { type: 'required', message: this.toast.toastError },
      ],
      'DataType': [
        { type: 'required', message: this.toast.toastError },
      ],
      'ClientInfo': [
        { type: 'required', message: this.toast.toastError },
      ],
      'ChangeNotes': [
        { type: 'required', message: this.toast.ChangeNotesError },
        { type: 'maxlength', message: this.toast.changenotesCharactersOnly },
        //{ type: 'minlength', message: this.toast.changenotesminCharactersOnly },
        { type: 'minLengthWithoutLeadingSpaces', message: this.toast.changenotesminCharactersOnly },
      ],
    }
  }


  ngOnInit(): void {
    console.log = function () { };
    this.Username = localStorage.getItem("UserName");
    this.SpinnerService.show();
    this.fieldservice.getListData()?.then((res) => {
      this.SpinnerService.hide();
      if (res.StatusCode == 200) {
        this.fieldValues = res.Data;
        if (this.fieldValues.length > 0) {
          this.fieldIdCount = res.Data[0].FieldId;
        }
        for (let i = 0; i < res.Data.length; i++) {
          this.fieldNamevalidation = res.Data[i].FieldName;
        }
      }
      else if (res.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }

    });
  }

  public addDiv() {
    this.trustedHtml = this.s.bypassSecurityTrustHtml("<div id='demo' class='modal-backdrop show'></div>")
  }

  addFieldModal() {
    this.isCheckedDirectInput = true;
    this.isCheckedClientInfo = false;
    this.createFieldForm.controls['DataType'].setValidators([Validators.required]);
    this.createFieldForm.get('ClientInfo').clearValidators();
    this.createFieldForm.get('DataType').updateValueAndValidity();
    this.createFieldForm.get('ClientInfo').updateValueAndValidity();
    this.displayAddField = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
    for (let i = 0; i < this.fieldValues.length; i++) {
      this.newarray = this.fieldValues;
      let array = [this.newarray[i].FieldName];
      this.fieldNamesArray.push(array);
    }

    // getId Data
    this.SpinnerService.show()
    this.fieldservice.getListIdData()?.then((res) => {
      if (res.StatusCode == 200) {
        if (res.Data != '') {
          this.fieldIdCount = res.Data[0]?.FieldId;
          this.newFeildId = this.fieldIdCount + 1;
          this.getAllStatus();
        }
        else {
          this.newFeildId = 1;
          this.getAllStatus();
        }
      }
      else if (res.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }

    });
    this.statusdata = null;
  }

  getAllStatus() {
    this.fieldservice.getAllFieldStatus()?.then((res) => {
      if (res.StatusCode == 200) {
        this.getMDDStatus();
        this.StatusValue = res.Data.FieldsStatus;
        this.DatatypeValue = res.Data.FieldsDataType;
      }
      else if (res.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  getMDDStatus() {
    this.fieldservice.getMddStatus()?.then((res) => {
      if (res.StatusCode == 200) {
        this.SpinnerService.hide();
        this.MDDtypeValue = res.Data;
      }
      else if (res.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  Closeaddfield() {
    this.displayAddField = 'none';
    this.clearFieldData();
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  CloseEditField() {
    this.displayEditField = "none";
    this.clearFieldData();
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  editFieldModal(fieldId: number) {
    this.displayEditField = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();

    this.fieldidmodel = {
      "FieldId": fieldId,
    }
    this.SpinnerService.show();
    this.fieldservice.postFieldId(this.fieldidmodel)?.then((data) => {
      if (data.StatusCode == 200) {
        if (data.Data != null) {
          this.getAllStatus();
          this.viewFieldJsonArray = data;
          if (this.viewFieldJsonArray.Data[0].DirectInput == true) {
            this.isCheckedClientInfo = false;
            this.isCheckedDirectInput = true;
            this.isReadOnlyDataType = false;
            this.isReadOnlyClientInfo = true;
            this.EditDirectInput = true;
            this.EditMDDLookup = false;
            this.fieldEditForm.get('ClientInfo').clearValidators();
            this.fieldEditForm.get('DataType').setValidators(Validators.required);
          }
          else {
            this.isCheckedClientInfo = true;
            this.isCheckedDirectInput = false;
            this.isReadOnlyDataType = true;
            this.isReadOnlyClientInfo = false;
            this.EditDirectInput = false;
            this.EditMDDLookup = true;
            this.fieldEditForm.get('DataType').clearValidators();
            this.fieldEditForm.get('ClientInfo').setValidators(Validators.required);
          }

          this.viewDatatypeJson = [{ "FieldType": this.viewFieldJsonArray.Data[0].FieldDataType }];
          this.viewEditedStatus = [{ "StatusName": this.viewFieldJsonArray.Data[0].StatusName }];
          this.viewClientinfoJson = [{ "ColumnNames": this.viewFieldJsonArray.Data[0].MDDColumnNames }];
          this.viewDatatype = this.viewDatatypeJson[0];
          this.viewClientinfo = this.viewClientinfoJson[0];
          this.statusdata = this.viewEditedStatus[0];
          this.editjsonvalue.Id = this.viewFieldJsonArray.Data[0].FieldId;
          this.editjsonvalue.FieldName = this.viewFieldJsonArray.Data[0].FieldName;
          this.editjsonvalue.DisplayName = this.viewFieldJsonArray.Data[0].DisplayName;
          this.editjsonvalue.DataType = this.viewFieldJsonArray.Data[0].FieldDataType;
          this.editjsonvalue.ColumnNames = this.viewFieldJsonArray.Data[0].MDDColumnNames;
          this.editjsonvalue.Status = this.viewFieldJsonArray.Data[0].StatusName;
          this.editjsonvalue.ChangeNotes = this.viewFieldJsonArray.Data[0].ChangeNotes;
          this.editjsonvalue.ConnectedBlocks = this.viewFieldJsonArray.Data[0].connectedblocks;
          this.editjsonvalue.DataTypeId = this.viewFieldJsonArray.Data[0].FieldDataTypeId;
          this.editjsonvalue.StatusId = this.viewFieldJsonArray.Data[0].StatusId;
          this.editjsonvalue.MDDLookup = this.viewFieldJsonArray.Data[0].MDDLookup;
          this.editjsonvalue.DirectInput = this.viewFieldJsonArray.Data[0].DirectInput;

          if (this.editjsonvalue.DataType == null) {
            this.fieldEditForm.get('DataType').setValue(null);
            // this.fieldEditForm.get('DataType').clearValidators();
          } else {
            this.fieldEditForm.get('ClientInfo').setValue(null);
            //this.fieldEditForm.get('ClientInfo').clearValidators();
          }

          if (this.editjsonvalue.ConnectedBlocks) {
            this.isReadstatusOnly = true;
            this.toggleInputField(true, 'editFieldName');
            //this.isDirectInputDisabled = true;
            //this.isClientInfoDisabled = true;
            // if (this.viewFieldJsonArray.Data[0].MDDLookup == true) {
            //   this.isReadOnlyClientInfo = true;
            // }

          } else {
            this.isReadstatusOnly = false;
          }
          this.toggleInputField(true, 'editFieldName');
          this.isReadOnlyClientInfo = true;
          if (this.viewFieldJsonArray.Data[0].MDDLookup == true) {
            this.isDirectInputDisabled = true;
            this.isClientInfoDisabled = false;
          } else {
            this.isDirectInputDisabled = false;
            this.isClientInfoDisabled = true;
          }

        } else {
          this.SpinnerService.hide();
          this.secondPopupOpen = true;
          this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }

      }
      else if (data.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      } else {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }
  onClearField() {
    this.clearFieldData();
  }
  saveField() {
    this.clearFilterInputs();
    this.table.reset();
    let Displaynamevalue = this.createFieldForm.get('DisplayName').value.trim();
    var withoutSpace = Displaynamevalue.replace(/ /g, "");

    let namevalue = this.createFieldForm.get('FieldName').value;
    var withoutspacename = namevalue.replace(/ /g, "");

    let Changenotesvalue = this.createFieldForm.get('ChangeNotes').value;
    var withoutSpaceForChange = Changenotesvalue.replace(/ /g, "");
    if (withoutSpace.length < 3 || withoutSpaceForChange.length < 5 || withoutspacename.length < 3) {
      this.secondPopupOpen = true;
      this.errorMessage = "Field Cannot be Added";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
    } else {

      if (this.createFieldForm.valid == true) {

        var DTypeId;
        var CNamesId;
        if (this.isReadOnlyClientInfo) {
          DTypeId = this.model.selectedDatatype.FieldDataTypeId;
          CNamesId = 0;
        }
        if (this.isReadOnlyDataType) {
          DTypeId = null;
          CNamesId = this.model.selectedMDDtype.ColumnId;
        }
        if (this.isDataType == undefined) {
          this.isDataType = true;

        }

        if (this.model.selectedMDDtype == undefined) {
          this.MDDColumName = "";
        }

        else {
          this.MDDColumName = this.model.selectedMDDtype.ColumnNames;
        }


        this.fieldjsonvalue = {

          "FieldId": 0,
          "FieldName": this.createFieldForm.controls['FieldName'].value.replace(/\s+/g, '').trim(),
          "DisplayName": this.createFieldForm.controls['DisplayName'].value.trim(),
          "FieldDataTypeId": DTypeId,
          "HintText": "",
          "ChangeNotes": this.createFieldForm.controls['ChangeNotes'].value.trim(),
          "IsDeleted": false,
          "StatusId": this.model.selectedStatus.StatusId,
          "StatusName": "",
          "CreatedOn": "2023-09-12T11:04:40.291Z",
          "ModifiedOn": "2023-09-12T11:04:40.291Z",
          "TransactionStatus": "",
          "connectedblocks": "",
          "DirectInput": this.isDataType,
          "MDDLookup": this.isMDDVal,
          "ColumnId": CNamesId,
          "MDDColumnNames": this.MDDColumName,
          "MDDColumnData": "",
          "ModifiedBy": "",
          "CreatedBy": this.Username,
          "InputType": ""

        }
        this.SpinnerService.show();
        this.fieldservice.postSaveFieldData(this.fieldjsonvalue)?.then((data) => {
          this.SpinnerService.hide();
          if (data.StatusCode == 200) {
            if (data.Data != null) {
              this.displayAddField = "none";
              this.successMessage = "Field added successfully"
              this.clearFieldData();
              this.displaySuccessModal = "block";
              this.renderer.addClass(document.body, 'modal-open');
              this.addDiv();
              this.getListField();
            }
            else {
              this.secondPopupOpen = true;
              this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
              this.displayErrorModal = "block";
              this.renderer.addClass(document.body, 'modal-open');
              this.addDiv();
            }
          }
          else if (data.status == 422) {
            this.SpinnerService.hide();
            this.clearCacheCommonLogout();
          }
          else if (data.StatusCode == 300) {
            this.secondPopupOpen = true;
            this.errorMessage = "Field name already exists, please provide unique field name";
            this.displayErrorModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
          }
          else {
            this.secondPopupOpen = true;
            this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
            this.displayErrorModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
          }

        });
      }

    }
  }

  clearFieldData() {
    this.formControlsToReset = [
      'FieldName',
      'DisplayName',
      'DataType',
      'Status',
      'ChangeNotes',
      'ClientInfo'
    ];

    this.formControlsToReset.forEach(controlName => {
      this.createFieldForm.controls[controlName].reset();
    });
    this.formControlsToReset.forEach(controlName => {
      this.fieldEditForm.controls[controlName].reset();
    });
    this.isReadOnlyDataType = false;
    this.isReadOnlyClientInfo = true;
    this.isMDDVal = false;
    this.isDataType = true;
    this.toggleInputField(false, 'FieldName');
    this.toggleInputField(false, 'editFieldName');
    this.createFieldForm.get('Direct').setValue("option1");
    this.createFieldForm.get('Clientdata').setValue(null);
  }

  getListField() {
    this.fieldservice.getListData()?.then((res) => {
      if (res.StatusCode == 200) {
        this.fieldValues = res.Data;
        this.isButtonEnabled = true;
        this.selectAllCheckBoxDisabled = this.fieldValues.length === 0;
        if (this.fieldValues.length > 0) {
          this.fieldIdCount = res.Data[0].FieldId;
        }
        for (let i = 0; i < res.Data.length; i++) {
          this.fieldNamevalidation = res.Data[i].FieldName;
        }
      }
      else if (res.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else {
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }

    });
  }

  UpdateFieldForm() {
    //this.getAllStatus();
    this.clearFilterInputs();
    this.table.reset();
    let Displaynamevalue = this.fieldEditForm.get('DisplayName').value.trim();
    var withoutSpace = Displaynamevalue.replace(/ /g, "");

    let namevalue = this.fieldEditForm.get('FieldName').value;
    var withoutspacename = namevalue.replace(/ /g, "");

    let Changenotesvalue = this.fieldEditForm.get('ChangeNotes').value;
    var withoutSpaceForChange = Changenotesvalue.replace(/ /g, "");
    if (withoutSpace.length < 3 || withoutSpaceForChange.length < 5 || withoutspacename.length < 3) {
      this.secondPopupOpen = true;
      this.errorMessage = "Field Cannot be Updated";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
    }

    if (this.EditDirectInput == undefined) {
      this.isDataType = true;

    }

    if (this.isCheckedClientInfo) {
      this.fieldDataTypeId = null;
    }
    else {
      this.selectedDatatypevalue = this.model.selectedDatatype.FieldType;
      var allDataTypeArray = this.DatatypeValue;
      this.findDataType = allDataTypeArray.find(item => item.FieldType === this.selectedDatatypevalue);
      this.fieldDataTypeId = this.findDataType.FieldDataTypeId;

    }

    if (this.isCheckedDirectInput) {
      this.MDDcoloumnId = null;
      this.MDDColoumnName = "";
    }
    else {
      this.MDDColoumnName = this.model.selectedMDDtype.ColumnNames;
      this.allMDDcoloumnArray = this.MDDtypeValue;
      this.findMDDcoloumn = this.allMDDcoloumnArray.find(item => item.ColumnNames === this.MDDColoumnName);
      this.MDDcoloumnId = this.findMDDcoloumn.ColumnId;
    }

    this.fieldUpdateJsonValue = {

      "FieldId": this.editjsonvalue.Id,
      "FieldName": this.fieldEditForm.controls['FieldName'].value.replace(/\s+/g, '').trim(),
      "DisplayName": this.fieldEditForm.controls['DisplayName'].value.trim(),
      "FieldDataTypeId": this.fieldDataTypeId,
      "HintText": "",
      "ChangeNotes": this.fieldEditForm.controls['ChangeNotes'].value.trim(),
      "IsDeleted": false,
      "StatusId": this.model.selectedStatus.StatusName == "Active" ? 1 : 2,
      "StatusName": "",
      "CreatedOn": "2023-09-12T11:04:40.291Z",
      "ModifiedOn": "2023-09-12T11:04:40.291Z",
      "TransactionStatus": "",
      "connectedblocks": "",
      "DirectInput": this.EditDirectInput,
      "MDDLookup": this.EditMDDLookup,
      "ColumnId": this.MDDcoloumnId,
      "MDDColumnNames": this.MDDColoumnName,
      "MDDColumnData": "",
      "ModifiedBy": this.Username,
      "CreatedBy": this.Username,
      "InputType": ""
    }

    this.SpinnerService.show();
    this.fieldservice.putUpdateFieldData(this.fieldUpdateJsonValue)?.then((data) => {
      this.SpinnerService.hide();
      if (data.StatusCode == 200) {
        if (data.Status == true) {
          this.displayEditField = "none";
          this.successMessage = "Field updated successfully"
          this.clearFieldData();
          this.displaySuccessModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
          this.getListField();
        }
      }
      else if (data.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }

      else if (data.StatusCode == 300) {
        this.secondPopupOpen = true;
        this.errorMessage = "Field name already exists, please provide unique field name";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.secondPopupOpen = true;
        this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }

    });

  }

  onDeletefield() {
    this.displayDeleteModal = "none";
    this.selectedFieldProduct = this.fieldValues.filter(field => field.checked).map(field => field.FieldId);
    if (this.selectedFieldProduct.length == 1) {
      this.totalDeleteId = this.selectedFieldProduct;
      var deleteData = {
        "FieldId": this.totalDeleteId,
        "DeletedBy": this.Username,
        "IsDeleted": true,
        "ModifiedOn": "2023-09-08T14:54:53.451Z"
      }
      this.SpinnerService.show();
      this.fieldservice.deleteFieldData(deleteData)?.then((res) => {
        this.SpinnerService.hide();
        if (res.StatusCode == 200) {
          if (res.Status == true) {
            this.successMessage = "Field(s) deleted successfully"
            this.displaySuccessModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getListField();
          }

        }
        else if (res.status == 422) {
          this.SpinnerService.hide();
          this.clearCacheCommonLogout();
        }
        else if (res.StatusCode == 406) {
          if (res.Status == false) {
            this.warningMessage = "Field(s) used in the blocks are not allowed to delete";
            this.displayWarningModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getListField();
          }
        }
        else if (res.StatusCode == 401 || res.StatusCode == 400) {
          this.SpinnerService.hide();
        }
        else {
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
      this.isButtonEnabled = true;
    }

    else {
      var totalDeleteIdArray: any = [];

      for (let i = 0; i < this.selectedFieldProduct.length; i++) {
        this.totalDeleteId = this.selectedFieldProduct[i];
        totalDeleteIdArray.push(this.totalDeleteId);
      }
      var deleteData = {
        "FieldId": totalDeleteIdArray,
        "DeletedBy": this.Username,
        "IsDeleted": true,
        "ModifiedOn": "2023-09-08T14:54:53.451Z"
      }
      this.SpinnerService.show();
      this.fieldservice.deleteFieldData(deleteData)?.then((res) => {
        this.SpinnerService.hide();
        if (res.StatusCode == 200) {
          if (res.Status == true) {
            this.successMessage = "Field(s) deleted successfully"
            this.displaySuccessModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getListField();
          }
        }
        else if (res.status == 422) {
          this.SpinnerService.hide();
          this.clearCacheCommonLogout();
        } 
        else if (res.StatusCode == 406) {
          if (res.Status == false) {
            this.warningMessage = "Field(s) used in the templates are not deleted & not used block(s) alone are deleted";
            this.displayWarningModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getListField();
          }
        }
        else if (res.StatusCode == 401 || res.StatusCode == 400) {
          this.SpinnerService.hide();
        }
        else {
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
      this.isButtonEnabled = true;
    }


  }

  onCloseDeleteModal() {
    this.displayDeleteModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
    this.isChecked = false;
    this.fieldValues.forEach(item => item.checked = false);
    this.isButtonEnabled = true;
  }
  onItemChange(value: any) {
    if (value == "option1") {
      this.isCheckedDirectInput = true;
      this.isCheckedClientInfo = false;
      this.isReadOnlyDataType = false;
      this.isReadOnlyClientInfo = true;
      this.isDataType = true;
      this.isMDDVal = false;
      this.toggleInputField(false, 'FieldName');
      this.createFieldForm.controls['DataType'].setValidators([Validators.required]);
      this.createFieldForm.get('ClientInfo').clearValidators();
      this.createFieldForm.controls['FieldName'].reset();
      this.createFieldForm.controls['ClientInfo'].reset();
      this.toggleInputField(false, 'FieldName');
    }
    else {
      this.isCheckedDirectInput = false;
      this.isCheckedClientInfo = true;
      this.isReadOnlyClientInfo = false;
      this.isReadOnlyDataType = true;
      this.isDataType = false;
      this.isMDDVal = true;
      this.toggleInputField(true, 'FieldName');
      this.createFieldForm.controls['FieldName'].reset();
      this.createFieldForm.get('DataType').reset();
      this.createFieldForm.get('DataType').clearValidators();
      this.createFieldForm.get('ClientInfo').setValidators([Validators.required]);

    }
    this.createFieldForm.get('DataType').updateValueAndValidity();
    this.createFieldForm.get('ClientInfo').updateValueAndValidity();
  }
  onEditItemChange(value) {
    if (value == "option1") {
      this.isCheckedDirectInput = true;
      this.isCheckedClientInfo = false;
      this.isReadOnlyDataType = false;
      this.isReadOnlyClientInfo = true;
      this.EditDirectInput = true;
      this.EditMDDLookup = false;
      this.fieldEditForm.controls['ClientInfo'].reset();
      this.fieldEditForm.get('ClientInfo').clearValidators();
      this.fieldEditForm.get('DataType').setValue(null);
      this.fieldEditForm.get('DataType').setValidators(Validators.required);
      this.toggleInputField(false, 'editFieldName');
      this.fieldEditForm.controls['FieldName'].reset();
    }
    else {
      this.isCheckedDirectInput = false;
      this.isCheckedClientInfo = true;
      this.EditDirectInput = false;
      this.EditMDDLookup = true;
      this.isReadOnlyDataType = true;
      this.isReadOnlyClientInfo = false;
      this.toggleInputField(true, 'editFieldName');
      this.fieldEditForm.controls['FieldName'].reset();
      this.fieldEditForm.controls['DataType'].reset();
      this.fieldEditForm.get('DataType').clearValidators();
      this.fieldEditForm.get('ClientInfo').setValue(null);
      this.fieldEditForm.get('ClientInfo').setValidators(Validators.required);
    }
    this.fieldEditForm.get('DataType').updateValueAndValidity();
    this.fieldEditForm.get('ClientInfo').updateValueAndValidity();
  }

  ConnectionError() {
    this.displayDatabaseFailure = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();

  }
  closeaddmodal() {
    this.displaySuccessModal = 'none';
    this.createFieldForm.reset();
  }
  closeupdatemodal() {
    this.displayUpdateModal = 'none';
    this.fieldEditForm.reset();
  }

  //#region popup modal - error, success, warning

  closeWarningModal() {
    this.displayWarningModal = "none";
    if (this.secondPopupOpen == true) {
      this.secondPopupOpen = false;
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      let myobj = document.getElementById("demo");
      myobj?.remove();
    }
  }

  closeErrorModal() {
    this.displayErrorModal = "none";
    if (this.secondPopupOpen == true) {
      this.secondPopupOpen = false;
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      let myobj = document.getElementById("demo");
      myobj?.remove();
    }
  }

  closeSuccessModal() {
    this.displaySuccessModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  //#endregion

  addClassInDashboard(content: string) {
    if (content == 'sidebar-open') {
      this.renderer.addClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    } else {
      this.renderer.removeClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    }
  }
  AvoidSpace(event) {
    let k = event ? event.which : event.charCode;
    if (k == 32) return false;
  }
  onKeyUpEvent(event: any) {
    this.keyupfieldValue = event.target.value;
  }
  checkAllCheckBox(event) {
    this.isChecked = event.target.checked;
    this.isButtonEnabled = !this.isChecked;
    this.fieldValues.forEach(item => item.checked = this.isChecked);
  }
  isAllCheckBoxChecked() {
    return this.fieldValues.length > 0 && this.fieldValues.every(item => item.checked);
  }

  deleteFieldModal() {
    this.displayDeleteModal = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
  }

  toggleSingleCheckbox(id: number, isChecked: boolean) {
    this.deleteId = id;
    this.anyCheckboxSelected = this.fieldValues.some(field => field.checked);
    this.isButtonEnabled = !this.anyCheckboxSelected;
  }

  updateFieldName(event: any) {
    let clientNamechange = event.ColumnNames;
    if (clientNamechange) {
      this.createFieldForm.controls['FieldName'].setValue(clientNamechange);
      this.fieldEditForm.controls['FieldName'].setValue(clientNamechange);
      this.toggleInputField(true, 'FieldName');
      this.toggleInputField(true, 'editFieldName');
    }
    else {
      this.toggleInputField(false, 'FieldName');
      this.toggleInputField(false, 'editFieldName');
    }
  }
  toggleInputField(isDisabled: boolean, inputId: string) {
    const inputElement = document.getElementById(inputId) as HTMLInputElement;
    if (inputElement) {
      inputElement.disabled = isDisabled;
    }
  }
  minLengthWithoutLeadingSpaces(minLength: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (value && value.trim().length < minLength) {
        return { minLengthWithoutLeadingSpaces: true };
      }

      return null;
    };
  }

  clearFilterInputs() {
    // Loop through all filter inputs and set their values to an empty string
    const filterInputs = document.querySelectorAll('.p-column-filter') as NodeListOf<HTMLInputElement>;
    filterInputs.forEach(input => {
      input.value = '';
    });

    // Clear the filter for all columns in the p-table
    this.table.filterGlobal('', 'contains');
  }

    //clear cache
    clearCacheCommonLogout() {
      this.CacheclearService.displayClearCacheWarningModal('This is an call for clear cache modal');
    }


}
